import React from 'react';
import './Search.css';

export default class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: '',
        };
        this.checkForEnter = this.checkForEnter.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <div className="search-div">
                <input
                    className="search-field"
                    type="text"
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                    onKeyPress={this.checkForEnter}
                ></input>
                <button
                    className="search-button"
                    onClick={() => this.props.onSearch(this.state.searchString)}
                >
                    Search
                </button>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            searchString: e.target.value,
        });
        this.props.onSearch(e.target.value);
    }

    checkForEnter(e) {
        if (e.key === 'Enter') {
            this.props.onSearch(e.target.value);
        }
    }
}
