import React from 'react';
import './Home.css';
import { APIurl } from '../../Router.js';
import PictureFormat from '../other/PictureFormat';
import SpeciesCard from './SpeciesCard.js';
import CategorySection from './CategorySection.js';
import TopBar from '../other/TopBar.js';
import Footer from '../other/Footer.js';

// The home page, where the entire catolog is displayed with sorting options

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            species: [],
            matches:
                this.props.location.state && this.props.location.state.matches
                    ? this.props.location.state.matches
                    : [],
            searchResult: [],
            mismatches:
                this.props.location.state &&
                this.props.location.state.mismatches
                    ? this.props.location.state.mismatches
                    : [],
            tree:
                this.props.location.state && this.props.location.state.tree
                    ? this.props.location.state.tree
                    : {},
            haveSearched: false,
            navigationOrder:
                this.props.location.state && this.props.location.state.navOrder
                    ? this.props.location.state.navOrder
                    : [],
            onlyInvasive: false,
            cameFrom: {}, // The last clicked on category
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.navigate = this.navigate.bind(this);
        this.resetCategories = this.resetCategories.bind(this);
        this.returnTo = this.returnTo.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    render() {
        // Window.scroll(0, 200);
        const matches = this.state.matches;
        const onlyInvasive = this.state.onlyInvasive;
        const unsortedTree = this.state.tree;
        const sortedTree = {};
        Object.keys(unsortedTree)
            .sort(function(a, b) {
                if (a === 'everything else') {
                    return 1;
                } else if (b === 'everything else') {
                    return -1;
                } else {
                    return 0;
                }
            })
            .forEach(function(key) {
                sortedTree[key] = unsortedTree[key];
            });
        const willShowWarning =
            this.state.species.filter(function(species) {
                if (onlyInvasive) {
                    return matches.includes(species.name) && species.invasive;
                } else {
                    return matches.includes(species.name);
                }
            }).length === 0;
        return (
            <div className="page">
                <TopBar needsBackButton={false} />
                <CategorySection
                    prevPic={this.state.cameFrom['picture']}
                    name={
                        this.state.navigationOrder.length >= 2
                            ? this.state.cameFrom['name']
                            : 'Home'
                    }
                    onlyInvasive={this.state.onlyInvasive}
                    handleCheckChange={this.handleCheckChange}
                    handleSearch={this.handleSearch}
                    navigationOrder={this.state.navigationOrder}
                    tree={sortedTree}
                    returnTo={this.returnTo}
                    resetCategories={this.resetCategories}
                    navigate={this.navigate}
                />
                {this.state.onlyInvasive && !willShowWarning ? (
                    <p className="invasive-warning">
                        Only showing invasive species
                    </p>
                ) : null}
                {willShowWarning ? (
                    <p className="invasive-warning">
                        {this.state.onlyInvasive
                            ? `${'There are currently no invasive species in '}${
                                  this.state.navigationOrder[
                                      this.state.navigationOrder.length - 1
                                  ]
                              }`
                            : `${'There are currently no species in '}${
                                  this.state.navigationOrder[
                                      this.state.navigationOrder.length - 1
                                  ]
                              }`}
                    </p>
                ) : null}
                <div className="card-table">
                    {this.state.haveSearched
                        ? this.state.searchResult
                              .filter(function(species) {
                                  if (onlyInvasive) {
                                      return (
                                          matches.includes(species.name) &&
                                          species.invasive
                                      );
                                  } else {
                                      return matches.includes(species.name);
                                  }
                              })
                              .sort(function(a, b) {
                                  if (a.name > b.name) {
                                      return 1;
                                  } else if (b.name > a.name) {
                                      return -1;
                                  } else {
                                      return 0;
                                  }
                              })
                              .map(item => {
                                  const formattedImage = new PictureFormat(
                                      item.image[0].name,
                                      item.image[0].credits,
                                      item.image[0].description,
                                      item.image[0].url
                                  );
                                  return (
                                      <SpeciesCard
                                          speciesName={item.name}
                                          scientificName={item.scientificName}
                                          invasive={item.invasive}
                                          image={formattedImage}
                                      />
                                  );
                              })
                        : this.state.species
                              .filter(function(species) {
                                  if (onlyInvasive) {
                                      return (
                                          matches.includes(species.name) &&
                                          species.invasive
                                      );
                                  } else {
                                      return matches.includes(species.name);
                                  }
                              })
                              .sort(function(a, b) {
                                  if (a.name > b.name) {
                                      return 1;
                                  } else if (b.name > a.name) {
                                      return -1;
                                  } else {
                                      return 0;
                                  }
                              })
                              .map(item => {
                                  const formattedImage = item.image[0]
                                      ? new PictureFormat(
                                            item.image[0].name,
                                            item.image[0].credits,
                                            item.image[0].description,
                                            item.image[0].url
                                        )
                                      : undefined;
                                  return (
                                      <SpeciesCard
                                          handleClick={value =>
                                              this.navigate(value)
                                          }
                                          speciesName={item.name}
                                          invasive={item.invasive}
                                          scientificName={item.scientificName}
                                          image={formattedImage}
                                          matches={this.state.matches}
                                          mismatches={this.state.mismatches}
                                          navOrder={this.state.navigationOrder}
                                          tree={this.state.tree}
                                      />
                                  );
                              })}
                </div>
                <Footer />
            </div>
        );
    }

    resetCategories() {
        const newMatches = this.state.matches.concat(this.state.mismatches);
        this.setState({
            matches: newMatches,
            mismatches: [],
            tree: this.state.fullTree,
            navigationOrder: [],
            cameFrom: {},
        });
    }

    navigate(value) {
        const tree = this.state.tree[value];
        const mismatches = getSomeEntries(false, this.state.tree, value);
        const matches = getSomeEntries(true, this.state.tree, value);
        const navOrder = this.state.navigationOrder;
        const from = {
            picture: tree['picture'],
            name: navOrder[navOrder.length - 1],
        };
        navOrder.push(value);
        this.setState({
            cameFrom: from,
            tree: tree,
            mismatches: this.state.mismatches.concat(mismatches),
            matches: matches,
            navigationOrder: navOrder,
        });
        this.handleSearch('');
        document.getElementsByClassName('search-field')[0].value = '';
    }

    /*
     *Filters out displayed species based on searched value. Searching the empty string should
     *make all species appear again
     */
    handleSearch(value) {
        const searchResult = this.state.species.filter(function(matches) {
            const sciMatch = matches.scientificName
                .toLowerCase()
                .includes(value.toLowerCase());
            return (
                matches.name.toLowerCase().includes(value.toLowerCase()) ||
                sciMatch
            );
        });
        const haveSearched = value !== '';
        this.setState({
            searchResult: searchResult,
            haveSearched: haveSearched,
        });
    }

    handleCheckChange() {
        this.setState({
            onlyInvasive: !this.state.onlyInvasive,
        });
    }

    // Returns the order of navigation to the given item
    returnTo(category) {
        let tree = this.state.fullTree;
        const navOrder = this.state.navigationOrder;
        let matches = [];
        let mismatches = [];
        let lastIndex;
        let cameFrom = {};
        if (category !== 'All') {
            for (const cat in navOrder) {
                if (navOrder[cat] === category && !lastIndex) {
                    matches = getSomeEntries(true, tree, category);
                    mismatches = mismatches.concat(
                        getSomeEntries(false, tree, category)
                    );
                    lastIndex = cat;
                    tree = tree[navOrder[lastIndex]];
                    cameFrom = {
                        name: navOrder[cat - 1],
                        picture: tree['picture'],
                    };
                } else if (!lastIndex) {
                    mismatches = mismatches.concat(
                        getSomeEntries(false, tree, navOrder[cat])
                    );
                    tree = tree[navOrder[cat]];
                }
            }
            this.setState({
                cameFrom: cameFrom,
                matches: matches,
                mismatches: mismatches,
                tree: tree,
                navigationOrder: navOrder.slice(
                    0,
                    navOrder.indexOf(category) + 1
                ),
            });
        } else {
            this.setState({
                matches: this.state.matches.concat(this.state.mismatches),
                mismatches: [],
                navigationOrder: [],
                tree: tree,
                cameFrom: {},
            });
        }
    }

    componentDidMount() {
        /*
         *For some reson, this isn't working when written by parsing the APIurl variable
         *Getting the catalog from the API and storing it in state as "species"
         */
        fetch(APIurl + '/species/simplified', {
            method: 'GET',
        })
            .then(function(response) {
                return response.json();
            })
            .then(responseData => {
                this.setState({ species: responseData.species });
            })
            .catch(err => console.log(err));

        fetch(APIurl + '/tree/species', {
            method: 'GET',
        })
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                sessionStorage.setItem(
                    'categoryTree',
                    JSON.stringify(responseData.tree)
                );
                if (
                    this.props.location.state &&
                    this.props.location.state.tree
                ) {
                    this.setState({
                        fullTree: responseData.tree,
                    });
                } else {
                    this.setState({
                        fullTree: responseData.tree,
                        tree: responseData.tree,
                        matches: getEntries(responseData.tree),
                    });
                }
            })
            .catch(err => console.log(err));
    }
}

/*
 *Iterates through tree, returning either mismatches or matches from it based/
 *on first boolean paramater value
 */
function getSomeEntries(wantMatches, tree, matchedBranch) {
    let mismatchArray = [];
    if (wantMatches) {
        return getEntries(tree[matchedBranch]);
    } else {
        for (const key in tree) {
            if (key !== matchedBranch) {
                mismatchArray = mismatchArray.concat(getEntries(tree[key]));
            }
        }
        return mismatchArray;
    }
}

function getEntries(branch) {
    let totalArray = [];
    if (typeof branch === 'string' || typeof branch === 'number') {
        return [];
    } else if (branch && Object.keys(branch).includes('Entries')) {
        return branch['Entries'];
    } else {
        for (const key in branch) {
            if (key !== 'picture' && key !== 'invasivePicture') {
                totalArray = totalArray.concat(getEntries(branch[key]));
            }
        }
        return totalArray;
    }
}

export default Home;
