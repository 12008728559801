import React from 'react';
import './Information.css';
import { APIurl } from '../../Router.js';
import TopBar from '../other/TopBar.js';
import { Link } from 'react-router-dom';
const queryString = require('query-string');

// The page for complete information about a specific species

class Information extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            sciName: '',
            classification: {},
            domaing: {},
            kingdom: {},
            phylum: {},
            class: {},
            family: {},
            genus: {},
            order: {},
            species: {},
            subSpecies: {},
            idChar: {},
            biology: {},
            habitat: {},
            diet: {},
            lookAlike: {},
            details: {},
            commonlySeen: {},
            range: {},
            otherNames: {},
            invasive: false,
            pictures: [],
            index: 0,
            translateValue: 0,
            expandImage: false,
            sections: [],
            leftSection: [],
            rightSection: [],
        };
    }

    // If invasive, display statement at top of page
    displayInvasive = () => {
        if (this.state.invasive) {
            return (
                <div className="invasive-tag">This species is invasive.</div>
            );
        }
    };

    // Displays the images
    renderSlides = () => {
        return this.state.pictures.map((value, index) => (
            <Slide
                key={index}
                image={value.image}
                imageClick={this.imageClick}
            />
        ));
    };

    // Increases slideshow index by 1
    nextSlide = () => {
        if (this.state.index === this.state.pictures.length - 1) {
            return this.setState({
                index: 0,
                translateValue: 0,
            });
        }

        this.setState(prevState => ({
            index: prevState.index + 1,
            translateValue: prevState.translateValue - this.slideWidth(),
        }));
    };

    // Decreases slideshow index by 1
    prevSlide = () => {
        if (this.state.index === 0) {
            return this.setState({
                index: this.state.pictures.length - 1,
                translateValue:
                    this.slideWidth() * -(this.state.pictures.length - 1),
            });
        }
        this.setState(prevState => ({
            translateValue: prevState.translateValue + this.slideWidth(),
            index: prevState.index - 1,
        }));
    };

    // Returns width of a slide
    slideWidth = () => {
        return document.querySelector('.slide').clientWidth;
    };

    // Handle dots on slideshow
    handleDotClick = i => {
        if (i === this.state.index) {
            return;
        }

        if (i > this.state.index) {
            this.setState(prevState => ({
                translateValue: -i * this.slideWidth(),
            }));
        } else {
            this.setState(prevState => ({
                translateValue:
                    prevState.translateValue +
                    (this.state.index - i) * this.slideWidth(),
            }));
        }

        this.setState({
            index: i,
        });
    };

    // Responds to a key input on a photo slide
    handleKeyPress = e => {
        if (e.key === 'ArrowRight' && this.state.expandImage) {
            this.nextSlide();
        } else if (e.key === 'ArrowLeft' && this.state.expandImage) {
            this.prevSlide();
        } else if (e.key === 'Escape' && this.state.expandImage) {
            this.exitImage();
        }
    };

    // Handle image expansion on click
    imageClick = () => {
        this.setState({
            expandImage: true,
        });
    };

    // Handle exit from image expansion
    exitImage = () => {
        this.setState({
            expandImage: false,
        });
    };

    // Sorts the information for the species into two columns of approximately equal length
    orderInfo = () => {
        const orderedInfo = [
            this.state.otherNames,
            this.state.classification,
            this.state.domain,
            this.state.kingdom,
            this.state.phylum,
            this.state.class,
            this.state.order,
            this.state.family,
            this.state.genus,
            this.state.species,
            this.state.subSpecies,
            this.state.details,
            this.state.idChar,
            this.state.biology,
            this.state.habitat,
            this.state.diet,
            this.state.lookAlike,
            this.state.commonlySeen,
            this.state.range,
        ];
        const orderedTitles = [
            'Other Name(s)',
            'Classification',
            'Domain',
            'Kingdom',
            'Phylum',
            'Class',
            'Order',
            'Family',
            'Genus',
            'Species',
            'Sub-Species',
            'Details',
            'Indentifying Characteristics',
            'Biology/Ecology',
            'Habitat',
            'Diet',
            'Look-Alikes',
            'Commonly Seen',
            'Range',
        ];

        const leftSection = [];
        const rightSection = [];
        let leftChars = 0;
        let rightChars = 4;
        // Roughly approximates the amount of lines of text
        for (let i = 0; i < orderedInfo.length; i++) {
            if (orderedInfo[i] !== undefined) {
                let lineCount = 1;
                for (let j = 0; j < orderedInfo[i].length; j++) {
                    if (orderedInfo[i][j]) {
                        lineCount += 1;
                    }
                }
                /*
                 * After each line of the information item has been counted, put the information
                 * in the column with less lines so far
                 */
                if (leftChars <= rightChars) {
                    leftSection.push(
                        <InfoSection
                            title={orderedTitles[i]}
                            info={orderedInfo[i]}
                        />
                    );
                    leftChars += lineCount;
                } else {
                    rightSection.push(
                        <InfoSection
                            title={orderedTitles[i]}
                            info={orderedInfo[i]}
                        />
                    );
                    rightChars += lineCount;
                }
            }
        }

        this.setState({
            leftSection: leftSection,
            rightSection: rightSection,
        });
    };

    // Pull from API and set species object state
    componentDidMount() {
        let name;
        if (this.props.location.state && this.props.location.state.name){
            name = this.props.location.state.name;
        } else {
            const parsed = queryString.parse(this.props.location.search);
            name = parsed["name"];
        }
	fetch(APIurl + '/species/get_live', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(results => {
                // Storing the species info retrieved from the API
                this.setState({
                    name: results.species.name,
                    sciName: results.species['scientific name'],
                    classification: results.species.information.Classification,
                    domain: results.species.information.Domain,
                    family: results.species.information.Family,
                    phylum: results.species.information.Phylum,
                    order: results.species.information.Order,
                    kingdom: results.species.information.Kingdom,
                    class: results.species.information.Class,
                    genus: results.species.information.Genus,
                    species: results.species.information.Species,
                    subSpecies: results.species.information['Sub-Species'],
                    idChar:
                        results.species.information[
                        'Identifying Characteristics'
                        ],
                    biology: results.species.information['Biology/Ecology'],
                    habitat: results.species.information.Habitat,
                    diet: results.species.information.Diet,
                    details: results.species.information.Details,
                    commonlySeen: results.species.information['Commonly Seen'],
                    range: results.species.information.Range,
                    otherNames: results.species.information['Other Name(s)'],
                    invasive: results.species.information.invasive
                        ? true
                        : false,
                    pictures: results.species.pictures,
                });
                if (results.species.information['Look-Alikes'] !== undefined) {
                    this.setState({
                        lookAlike: results.species.information['Look-Alikes'],
                    });
                } else {
                    this.setState({
                        lookAlike: results.species.information['Look Alikes'],
                    });
                }
                this.orderInfo();
            })
            .catch(err => window.location.replace("https://maineaquaticfieldguide.org/#/"))

        document.onkeydown = this.handleKeyPress;
    }

    render() {
        return (
            <div className="page">
                <TopBar needsBackButton={true} />

                {this.displayInvasive()}

                <Link
                    to={{
                        pathname: '/',
                        state: {
                            navOrder: this.props.location.state && this.props.location.state.navOrder
                                        ? this.props.location.state.navOrder
                                        : null,
                            matches: this.props.location.state && this.props.location.state.navOrder
                                        ? this.props.location.state.matches
                                        : null,
                            mismatches: this.props.location.state && this.props.location.state.mismatches
                                        ? this.props.location.state.mismatches
                                        : null,
                            tree: this.props.location.state && this.props.location.state.tree
                                        ? this.props.location.state.tree
                                        : null,
                        },
                    }}
                >
                    <label className="back-button"> ← Back to Catalog </label>
                </Link>

                <div className="flex">
                    <div className="column">
                        <NameSection
                            title={'Common Name'}
                            info={this.state.name}
                        />
                        {this.state.sciName ? (
                            <NameSection
                                title={'Scientific Name'}
                                info={this.state.sciName}
                            />
                        ) : null}

                        {this.state.leftSection}
                    </div>
                    <div className="column">
                        {this.state.expandImage ? (
                            <ExpandedImage
                                image={
                                    this.state.pictures[this.state.index].image
                                }
                                caption={
                                    this.state.pictures[this.state.index]
                                        .description
                                }
                                title={
                                    this.state.pictures[this.state.index].name
                                }
                                credits={
                                    this.state.pictures[this.state.index].credits
                                }
                                exitImage={this.exitImage}
                                nextSlide={this.nextSlide}
                                prevSlide={this.prevSlide}
                                length={this.state.pictures.length}
                            />
                        ) : null}

                        <div className="slideshow">
                            <div
                                className="slideshow-wrapper"
                                style={{
                                    transform: `translateX(${this.state.translateValue}px)`,
                                    transition: 'transform ease-out 0.45s',
                                }}
                            >
                                {this.renderSlides()}
                            </div>

                            {this.state.pictures.length > 1 ? (
                                <Dots
                                    index={this.state.index}
                                    images={this.state.pictures}
                                    dotClick={this.handleDotClick}
                                />
                            ) : null}

                            {this.state.pictures.length > 1 ? (
                                <LeftArrow prevSlide={this.prevSlide} />
                            ) : null}
                            {this.state.pictures.length > 1 ? (
                                <RightArrow nextSlide={this.nextSlide} />
                            ) : null}
                        </div>
                        <p className="slideshow-subscript">Click on photo for more info</p>
                        {this.state.rightSection}
                    </div>
                </div>
            </div>
        );
    }
}

const NameSection = props => {
    return (
        <div className="NameSection">
            <h3 className="SectionHead"> {props.title} </h3>
            <ul> {props.title === "Scientific Name" ? <i>{props.info}</i> : props.info}</ul>
        </div>
    );
};

const InfoSection = props => {
    return (
        <div className="InfoSection">
            <h3 className="SectionHead"> {props.title} </h3>
            <ul>
                {Object.values(props.info).filter(val => val !== null && val !== "null").map(val => {
                    const filteredInfo = Object.values(props.info).filter(val => val !== null && val !== "null")
                    if (
                        filteredInfo.length === 1
                    ) {
                        return <div> {val}</div>;
                    } else {
                        return handleTabs(val);
                    }
                })}
            </ul>
        </div>
    );
};

const Slide = props => {
    return (
        <div className="slide">
            <img
                className="slide-image"
                src={props.image}
                onClick={props.imageClick}
            />
        </div>
    );
};

const ExpandedImage = props => {
    const hasTitle = props.title !== 'unknown';
    const hasCredit = props.credits !== 'unknown';
    const hasCaption = props.caption !== '';
    return (
        <div className="expand-box">
            <div className="expand-background" onClick={props.exitImage} />
            <span className="close" onClick={props.exitImage}>
                {' '}
                ×{' '}
            </span>
            {hasTitle ? (
                <div className="title"> {`Title: ${props.title}`} </div>
            ) : null}
            {hasCredit ? (
                <div className="credits"> {`By: ${props.credits}`} </div>
            ) : null}
            <img className="expand-image" src={props.image} />
            {hasCaption ? (
                <div className="caption">
                    {' '}
                    {`Description: ${props.caption}`}{' '}
                </div>
            ) : null}

            {props.length > 1 ? (
                <OverlayRightArrow nextSlide={props.nextSlide} />
            ) : null}
            {props.length > 1 ? (
                <OverlayLeftArrow prevSlide={props.prevSlide} />
            ) : null}
        </div>
    );
};

const OverlayRightArrow = props => {
    return (
        <div className="overlay-rightArrow arrow" onClick={props.nextSlide}>
            <div className="overlay-right">{'›'}</div>
        </div>
    );
};

const OverlayLeftArrow = props => {
    return (
        <div className="overlay-leftArrow arrow" onClick={props.prevSlide}>
            <div className="overlay-left">{'‹'}</div>
        </div>
    );
};

const RightArrow = props => {
    return (
        <div className="rightArrow arrow" onClick={props.nextSlide}>
            <div className="right">{'›'}</div>
        </div>
    );
};

const LeftArrow = props => {
    return (
        <div className="leftArrow arrow" onClick={props.prevSlide}>
            <div className="left">{'‹'}</div>
        </div>
    );
};

const Dot = ({ id, active, dotClick }) => {
    const name = active ? 'dot active' : 'dot';
    return (
        <div className={name} onClick={() => dotClick(id)}>
            {' '}
        </div>
    );
};

const Dots = ({ index, images, dotClick }) => {
    const DotGroup = images.map((image, i) => {
        const active = i === index ? true : false;
        return <Dot key={i} id={i} active={active} dotClick={dotClick} />;
    });

    return <div className="dots-container">{DotGroup}</div>;
};

// Iterates through the given string and, for each tab, indents the list item by one
function handleTabs(item) {
    let lastChar = item.charAt(0);
    let returnString = `<li>${item}</li>`;
    for (let i = 1; i <= item.length && lastChar === '\t'; i++) {
        lastChar = item.charAt(i);
        returnString = `<ul>${returnString}</ul>`;
    }
    return <div dangerouslySetInnerHTML={{ __html: returnString }}></div>;
}
export default Information;
