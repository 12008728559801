import React from 'react';
import TopBar from './Components/TopBar.js';
import SideBar from './Components/SideBar.js';
import Popup from './Components/Popup.js';
import { PictureFrame, imageWithInfo } from './NewEntry.js';
import './NewCategory.css';
import { Link } from 'react-router-dom';
import { APIurl } from './Router';
import { imageurl } from './Router';
import MoveEntryPopup from './Components/MoveEntryPopup.js';
import {
    categoryNameAcceptable,
    categoryParentAcceptable,
} from './EditCategory.js';

const async = require('async');

class NewCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevInfo:
                this.props.location.state.prevInfo === undefined
                    ? { image: undefined, superCategory: '', name: '' }
                    : this.props.location.state.prevInfo,
            movedSpecies:
                this.props.location.state === undefined ||
                    this.props.location.state.movedSpecies === undefined
                    ? []
                    : this.props.location.state.movedSpecies,
            addedSpecies:
                this.props.location.state === undefined ||
                    this.props.location.state.addedSpecies === undefined
                    ? []
                    : this.props.location.state.addedSpecies,
            members:
                this.props.location.state === undefined ||
                    this.props.location.state.members === undefined
                    ? []
                    : this.props.location.state.members,
            speciesInfo:
                this.props.location.state === undefined
                    ? undefined
                    : this.props.location.state.newInfo,
            newGuyName:
                this.props.location.state === undefined
                    ? undefined
                    : this.props.location.state.newGuyName,
        };
        this.confirmMove = this.confirmMove.bind(this);
    }

    confirmMove(entry) {
        // Should propose move of entry to the category that this page is editting
        entry.category = this.state.name;

        const newMovedSpecies = this.state.movedSpecies.concat([entry.name]);
        const newMembers = this.state.members.concat([entry.name]);
        this.setState({ movedSpecies: newMovedSpecies, members: newMembers });
    }

    componentDidMount() {
        if (this.state.speciesInfo) {
            this.setState({
                addedSpecies: this.state.addedSpecies.concat([
                    this.state.speciesInfo,
                ]),
            });
        }
        if (this.state.newGuyName) {
            const justNames = [];
            for (let i = 0; i < this.state.addedSpecies.length; i++) {
                justNames.push(this.state.addedSpecies[i].name);
            }
            const withNewMembers = this.state.members
                .concat([this.state.newGuyName].concat(justNames))
                .concat(this.state.movedSpecies);
            this.setState({ members: withNewMembers });
        }
    }

    render() {
        return (
            <div id="New-cat">
                <TopBar page="New Category" />
                <SideBar />
                <MainContents
                    prevInfo={this.state.prevInfo}
                    movedSpecies={this.state.movedSpecies}
                    addedSpecies={this.state.addedSpecies}
                    members={this.state.members}
                    confirmMove={this.confirmMove}
                />
            </div>
        );
    }
}

class MainContents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupActive: false,
            image: this.props.prevInfo.image,
            superCategory: this.props.prevInfo.superCategory,
            name:
                this.props.prevInfo.name === undefined
                    ? ''
                    : this.props.prevInfo.name,
            members: this.props.members,
            movedSpecies: this.props.movedSpecies,
            addedSpecies: this.props.addedSpecies,
            invasiveImage: this.props.prevInfo.invasiveImage,
            invasivePopup: false,
            tree: {},
        };
        this.handlePopupCancelClick = this.handlePopupCancelClick.bind(this);
        this.handlePopupSubmitClick = this.handlePopupSubmitClick.bind(this);
        this.handleInvasivePopupSubmit = this.handleInvasivePopupSubmit.bind(
            this
        );
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.toggleMoveEntryPopup = this.toggleMoveEntryPopup.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);
        this.invasiveImageClick = this.invasiveImageClick.bind(this);
    }

    handleNameChange(e) {
        this.setState({ name: e.target.value.toLowerCase() });
    }

    handlePopupCancelClick() {
        this.setState({ popupActive: false });
        this.setState({ invasivePopup: false });
    }

    handlePopupSubmitClick(title, imageSource, credits) {
        this.setState({ image: new imageWithInfo(title, imageSource, credits) });
    }

    componentDidMount() {
        fetch(`${APIurl}/tree/all`, { method: 'GET' })
            .then(response => response.json())
            .then(response => {
                this.setState({ tree: response.tree });
            });
    }

    handleSubmitClick() {
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('parentName', this.state.superCategory);
        formData.append('picture', this.state.image.imageSource);
        if (this.state.movedSpecies) {
            formData.append(
                'movedSpecies',
                JSON.stringify(this.state.movedSpecies)
            );
        }
        if (this.state.invasiveImage && this.state.invasiveImage.imageSource) {
            formData.append(
                'invasivePicture',
                this.state.invasiveImage.imageSource
            );
        }

        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: sessionStorage.getItem(
                    'token'
                ) /* , 'Content-Type': 'multipart/form-data' */,
            }),
            body: formData,
        };

        const cat = this.state.name;
        /*
         *This line allows the browser to automatically set the boundary between form rows
         *delete options.headers['Content-Type'];
         */

        fetch(`${APIurl}/tree/add_complete`, options)
            .then(function (response) {
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    console.log(data);
                } else {
                    // Adding any newly created species
                    async.forEachOf(
                        this.props.addedSpecies,
                        function (value, key, callback) {
                            // Before implementing, need to figure out how to handle storing a json file

                            const parsedName = value.name
                                .toLowerCase()
                                .replace(/[- ]/, '_');
                            const imageLocations = [];
                            const images = value.images;
                            const sciName = value.sciName;
                            const name = value.name;
                            const category = cat;
                            const invasive = value.invasive;
                            const information = makeInformation(value);

                            fetch(`${APIurl}/species/next`, { method: 'get' })
                                .then(response => response.json())
                                .then(data => {
                                    const id = data.speciesId;

                                    async.forEachOf(
                                        images,
                                        function (image, key, callback) {
                                            const form = new FormData();

                                            form.append('name', image.title);
                                            form.append(
                                                'credits',
                                                image.credits
                                            );
                                            form.append(
                                                'description',
                                                image.description
                                            );
                                            form.append('speciesId', id + key);
                                            form.append(
                                                'image',
                                                image.imageSource
                                            );

                                            const options = {
                                                method: 'post',
                                                headers: {
                                                    'Content-Type':
                                                        'multipart/form-data',
                                                    Authorization: sessionStorage.getItem(
                                                        'token'
                                                    ),
                                                },
                                                body: form,
                                            };

                                            // This line allows the browser to automatically set the boundary between form rows
                                            delete options.headers[
                                                'Content-Type'
                                            ];

                                            fetch(
                                                `${APIurl}/catalog/add_image`,
                                                options
                                            )
                                                .then(response =>
                                                    response.json()
                                                )
                                                .then(data => {
                                                    imageLocations.push(
                                                        data.name
                                                    );
                                                    callback();
                                                })
                                                .catch(err => callback(err));
                                        },
                                        function (err) {
                                            if (err) {
                                                console.log(err);
                                            } else {
                                                const orderOptions = {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type':
                                                            'application/json',
                                                        Authorization: sessionStorage.getItem(
                                                            'token'
                                                        ),
                                                    },
                                                    body: JSON.stringify({
                                                        imageArray: imageLocations,
                                                    }),
                                                };

                                                fetch(
                                                    `${APIurl}/catalog/order_images`,
                                                    orderOptions
                                                )
                                                    .then(response =>
                                                        response.json()
                                                    )
                                                    .then(data => {
                                                        if (data.err) {
                                                            console.log(
                                                                data.err
                                                            );
                                                        } else {
                                                            const data = JSON.stringify(
                                                                {
                                                                    name: name,
                                                                    scientificName: sciName,
                                                                    category: category,
                                                                    invasive: invasive,
                                                                    jsonName: `${parsedName}.json`,
                                                                    information: information,
                                                                    pictures: imageLocations,
                                                                }
                                                            );

                                                            fetch(
                                                                `${APIurl}/species/add`,
                                                                {
                                                                    method:
                                                                        'post',
                                                                    headers: {
                                                                        'Content-Type':
                                                                            'application/json',
                                                                        Authorization: sessionStorage.getItem(
                                                                            'token'
                                                                        ),
                                                                    },
                                                                    body: data,
                                                                }
                                                            )
                                                                .then(
                                                                    response =>
                                                                        response.json()
                                                                )
                                                                .then(data => {
                                                                    if (
                                                                        data.err
                                                                    ) {
                                                                        callback(
                                                                            data.err
                                                                        );
                                                                    } else {
                                                                        callback();
                                                                    }
                                                                });
                                                        }
                                                    })
                                                    .catch(err =>
                                                        console.log(err)
                                                    );
                                            }
                                        }
                                    );
                                })
                                .catch(err => console.log(err));
                        },
                        function (err) {
                            if (err) {
                                console.log(err);
                            }
                        }
                    );
                }
            });
    }

    handleDeleteClick() {
        this.setState({ image: undefined });
    }

    handleInvasivePopupSubmit(title, imageSource, credits) {
        this.setState({
            invasiveImage: new imageWithInfo(title, imageSource, credits),
        });
    }

    toggleMoveEntryPopup() {
        this.setState({ showMovementPopup: !this.state.showMovementPopup });
    }

    handleImageClick() {
        this.setState({ popupActive: true });
    }

    invasiveImageClick() {
        this.setState({ invasivePopup: true });
    }

    render() {
        return (
            <div className="Main-contents">
                {this.state.showMovementPopup ? (
                    <MoveEntryPopup
                        moveEntry={this.props.confirmMove}
                        thisCategory={this.state.name}
                        containedEntries={this.state.movedSpecies}
                        closePopup={this.toggleMoveEntryPopup}
                    />
                ) : null}
                {this.state.image === undefined ||
                    this.state.image.imageSource === undefined ? (
                    <div className="Picture-holder">
                        Primary Image*
                        <button
                            className="Add-image"
                            onClick={() => {
                                this.setState({ popupActive: true });
                            }}
                        >
                            +
                            </button>
                    </div>
                ) : (
                    <div className="Picture-holder">
                        <PictureFrame
                            singleImage={true}
                            source={
                                typeof this.state.image.imageSource === 'string'
                                    ? this.state.image.imageSource
                                    : URL.createObjectURL(
                                        this.state.image.imageSource
                                    )
                            }
                            handleImageClick={this.handleImageClick}
                            handleDeleteClick={this.handleDeleteClick}
                        />
                    </div>
                )}
                {this.state.invasiveImage === undefined ||
                    this.state.invasiveImage === `${imageurl}undefined` ? (
                    <div className="invasiveImageHolder">
                        Category Invasive Image
                        <button
                            className="Add-image"
                            onClick={() => {
                                this.setState({ invasivePopup: true });
                            }}
                        >
                            +
                            </button>
                    </div>
                ) : (
                    <div className="invasiveImageHolder">
                        <PictureFrame
                            singleImage={true}
                            invasiveImage={true}
                            source={
                                typeof this.state.invasiveImage.imageSource ===
                                    'string'
                                    ? this.state.invasiveImage.imageSource
                                    : URL.createObjectURL(
                                        this.state.invasiveImage.imageSource
                                    )
                            }
                            handleImageClick={this.invasiveImageClick}
                            handleDeleteClick={() => {
                                this.setState({ invasiveImage: undefined });
                            }}
                        />
                    </div>
                )}
                {this.state.popupActive ? (
                    <Popup
                        contents={this.state.image}
                        isAddImage="true"
                        handleSubmitClick={this.handlePopupSubmitClick}
                        handleCancelClick={this.handlePopupCancelClick}
                    />
                ) : null}
                {this.state.invasivePopup ? (
                    <Popup
                        contents={this.state.invasiveImage}
                        isInvasiveAddImage={true}
                        handleSubmitClick={this.handleInvasivePopupSubmit}
                        handleCancelClick={this.handlePopupCancelClick}
                    />
                ) : null}
                <div id="Category-info">
                    <span id="Left-input">
                        <label>Category Name</label>
                        <input
                            type="text"
                            value={this.state.name}
                            onChange={this.handleNameChange}
                        />
                    </span>
                    <span id="Right-input">
                        <label>Parent Category</label>: &nbsp;
                        {this.state.superCategory}
                    </span>
                    {this.state.memberSpecies === undefined ? (
                        <div id="Entries-wrapper">
                            <div
                                readOnly={true}
                                id="Entries-section"
                                rows="2"
                                cols="20"
                            >
                                {this.props.members.map(function (item) {
                                    return `${item},  `;
                                })}
                            </div>
                            <button
                                className="Entries-section-left-button"
                                onClick={this.toggleMoveEntryPopup}
                            >
                                Move Existing Entry
                            </button>
                            <Link
                                to={{
                                    pathname: '/lsadmin/new_entry',
                                    state: {
                                        prevInfo: {
                                            name: this.state.name,
                                            image: this.state.image,
                                            superCategory: this.state
                                                .superCategory,
                                            invasiveImage: this.state
                                                .invasiveImage,
                                        },
                                        memberSpecies: this.props.members,
                                        new: true,
                                        addedSpecies: this.props.addedSpecies,
                                        movedSpecies: this.props.movedSpecies,
                                    },
                                }}
                            >
                                <button className="Entries-section-right-button">
                                    Create New Entry
                                </button>
                            </Link>
                        </div>
                    ) : null}
                </div>
                <div className="Bottom-buttons">
                    {this.state.image !== undefined &&
                        categoryNameAcceptable(
                            this.state.name,
                            this.state.oldName,
                            this.state.tree
                        ) &&
                        categoryParentAcceptable(
                            this.state.superCategory,
                            this.state.oldName,
                            this.state.tree
                        ) ? (
                        <Link to="/lsadmin/changes">
                            <button
                                onClick={this.handleSubmitClick}
                                id="Cat-submit-button"
                            >
                                Submit
                                </button>
                        </Link>
                    ) : null}
                    <button
                        id="Cat-cancel-button"
                        onClick={() => {
                            window.history.back();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}

function makeInformation(value) {
    const information = {};
    for (const key in value.otherInfo) {
        if (value.otherInfo[key] !== undefined) {
            information[key] = value.otherInfo[key];
        }
    }
    const parsedInfo = {};
    for (const subCat in information) {
        parsedInfo[subCat] = information[subCat].split('\n');
        if (parsedInfo[subCat][parsedInfo[subCat].length - 1] === '') {
            parsedInfo[subCat].pop();
        }
    }
    return parsedInfo;
}

export default NewCategory;
