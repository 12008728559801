import React from 'react';
import TopBar from './Components/TopBar.js';
import SideBar from './Components/SideBar.js';
import Popup from './Components/Popup.js';
import { PictureFrame, imageWithInfo } from './NewEntry.js';
import './NewCategory.css';
import './EditCategory.css';
import { Link } from 'react-router-dom';
import { APIurl } from './Router';
import { imageurl } from './Router';
import MoveEntryPopup from './Components/MoveEntryPopup.js';

const async = require('async');

/*
 *Foo
 *hooray
 */

class EditCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryName: this.props.location.state.categoryName,
            categoryParents: this.props.location.state.categoryParents,
            image:
                this.props.location.state && this.props.location.state.prevInfo
                    ? this.props.location.state.prevInfo.image
                    : undefined,
            invasiveImage:
                this.props.location.state && this.props.location.state.prevInfo
                    ? this.props.location.state.prevInfo.invasiveImage
                    : undefined,
            prevInfo:
                this.props.location.state && this.props.location.state.prevInfo
                    ? this.props.location.state.prevInfo
                    : {},
            movedSpecies:
                this.props.location.state === undefined ||
                    this.props.location.state.movedSpecies === undefined
                    ? []
                    : this.props.location.state.movedSpecies,
            addedSpecies:
                this.props.location.state === undefined ||
                    this.props.location.state.addedSpecies === undefined
                    ? []
                    : this.props.location.state.addedSpecies,
            members:
                this.props.location.state === undefined ||
                    this.props.location.state.members === undefined
                    ? []
                    : this.props.location.state.members,
            speciesInfo:
                this.props.location.state === undefined
                    ? undefined
                    : this.props.location.state.newInfo,
            newGuyName:
                this.props.location.state === undefined
                    ? undefined
                    : this.props.location.state.newGuyName,
        };
        this.confirmMove = this.confirmMove.bind(this);
        this.getImageUrl = this.getImageUrl.bind(this);
        this.getMemberSpecies = this.getMemberSpecies.bind(this);
        this.getInvasiveImage = this.getInvasiveImage.bind(this);
        this.handlePopupSubmitClick = this.handlePopupSubmitClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleInvasivePopupSubmit = this.handleInvasivePopupSubmit.bind(
            this
        );
        this.deleteInvasiveImage = this.deleteInvasiveImage.bind(this);
    }

    confirmMove(entry) {
        const newMembers = this.state.members.concat([entry.name]);
        const newMoved = this.state.movedSpecies.concat([entry.name]);
        this.setState({
            movedSpecies: newMoved,
            members: newMembers,
        });
    }

    componentDidMount() {
        if (this.state.speciesInfo) {
            this.setState({
                addedSpecies: this.state.addedSpecies.concat([
                    this.state.speciesInfo,
                ]),
            });
        }
        if (this.state.newGuyName) {
            const justNames = [];
            for (let i = 0; i < this.state.addedSpecies.length; i++) {
                justNames.push(this.state.addedSpecies[i].name);
            }
            const withNewMembers = this.state.members
                .concat([this.state.newGuyName].concat(justNames))
                .concat(this.state.movedSpecies);
            this.setState({ members: withNewMembers });
        }
        const URL = `${APIurl}/tree/all`;
        fetch(URL, {
            method: 'GET',
            headers: new Headers({
                Authorization: sessionStorage.getItem('token'),
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    console.log(response);
                } else {
                    if (!this.state.invasiveImage) {
                        this.setState({
                            invasiveImage: this.getInvasiveImage(response.tree),
                        });
                    }
                    if (!this.state.image) {
                        this.setState({
                            image: this.getImageUrl(response.tree),
                        });
                    }
                    this.setState({
                        tree: response.tree,
                    });
                }
            });

        const speciesurl = `${APIurl}/tree/species`;
        let oldMembers;
        fetch(speciesurl, {
            method: 'GET',
            headers: new Headers({
                Authorization: sessionStorage.getItem('token'),
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    console.log(response);
                } else {
                    oldMembers = this.getMemberSpecies(response.tree);
                    this.setState({
                        members: oldMembers.concat(this.state.members),
                    });
                }
            });
    }

    getInvasiveImage(tree) {
        let subTree = tree;
        for (let i = 0; i < this.state.categoryParents.length; i++) {
            subTree = subTree[this.state.categoryParents[i]];
        }
        return `${imageurl}${subTree[this.state.categoryName].invasivePicture}`;
    }

    getImageUrl(tree) {
        let subTree = tree;
        for (let i = 0; i < this.state.categoryParents.length; i++) {
            subTree = subTree[this.state.categoryParents[i]];
        }
        return `${imageurl}${subTree[this.state.categoryName].picture}`;
    }

    getMemberSpecies(tree) {
        let subTree = tree;
        for (let i = 0; i < this.state.categoryParents.length; i++) {
            subTree = subTree[this.state.categoryParents[i]];
        }
        if (subTree[this.state.categoryName]['Entries'] === undefined) {
            return [];
        } else {
            return subTree[this.state.categoryName]['Entries'];
        }
    }

    handlePopupSubmitClick(title, imageSource, credits) {
        this.setState({
            image: new imageWithInfo(title, imageSource, credits),
            addedImage: true,
        });
    }

    handleInvasivePopupSubmit(title, imageSource, credits) {
        this.setState({
            invasiveImage: new imageWithInfo(title, imageSource, credits),
            addedInvasiveImage: true,
        });
    }

    handleDeleteClick() {
        this.setState({ image: undefined });
    }

    deleteInvasiveImage() {
        this.setState({ invasiveImage: undefined });
    }

    render() {
        return (
            <div id="New-cat">
                <TopBar page="Edit Category" />
                <SideBar />
                <MainContents
                    categoryName={this.state.categoryName}
                    categoryParents={this.state.categoryParents}
                    addedSpecies={this.state.addedSpecies}
                    members={this.state.members}
                    movedSpecies={this.state.movedSpecies}
                    confirmMove={this.confirmMove}
                    invasiveImage={this.state.invasiveImage}
                    addedImage={this.state.addedImage}
                    addedInvasiveImage={this.state.addedInvasiveImage}
                    image={this.state.image}
                    handlePopupSubmitClick={this.handlePopupSubmitClick}
                    handleDeleteClick={this.handleDeleteClick}
                    handleInvasivePopupSubmit={this.handleInvasivePopupSubmit}
                    deleteInvasiveImage={this.deleteInvasiveImage}
                />
            </div>
        );
    }
}

class MainContents extends React.Component {
    constructor(props) {
        super(props);
        const name = this.props.categoryName;
        const lastParent = this.props.categoryParents[
            this.props.categoryParents.length - 1
        ];
        this.state = {
            popupActive: false,
            image: this.props.image,
            superCategory: lastParent,
            name: name,
            oldName: name,
            memberSpecies: this.props.members,
            showMovementPopup: false,
            invasiveImage: this.props.invasiveImage,
            invasivePopup: false,
            addedImage: false,
            addedInvasiveImage: false,
            movedSpecies: this.props.movedSpecies,
            newSpecies: this.props.addedSpecies,
            tree: {},
            hasNoSubCategories: false,
        };
        this.handlePopupCancelClick = this.handlePopupCancelClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);

        this.toggleMoveEntryPopup = this.toggleMoveEntryPopup.bind(this);
    }

    handleNameChange(e) {
        this.setState({ name: e.target.value });
    }

    handlePopupCancelClick() {
        this.setState({ popupActive: false, invasivePopup: false });
    }

    handleSubmitClick() {
        const formData = new FormData();
        if (this.props.addedInvasiveImage) {
            formData.append(
                'invasivePicture',
                this.props.invasiveImage.imageSource
            );
        }
        if (this.props.addedImage) {
            formData.append('picture', this.props.image.imageSource);
        }
        if (this.props.movedSpecies.length > 0) {
            formData.append(
                'movedSpecies',
                JSON.stringify(this.props.movedSpecies)
            );
        }
        formData.append('name', this.state.name);
        formData.append('oldName', this.state.oldName);

        const options = {
            method: 'POST',
            headers: new Headers({
                Authorization: sessionStorage.getItem('token'),
            }),
            body: formData,
        };

        const cat = this.state.name;
        const addedSpecies = this.props.addedSpecies;
        fetch(`${APIurl}/tree/update`, options)
            .then(response => {
                return response.json();
            })
            .then(data => {
                let id;
                fetch(`${APIurl}/species/next`, { method: 'get' })
                    .then(response => response.json())
                    .then(data => {
                        id = data.speciesId;
                    })
                    .then(function () {
                        async.forEachOf(
                            addedSpecies,
                            function (value, outKey, cb) {
                                // Before implementing, need to figure out how to handle storing a json file
                                const parsedName = value.name
                                    .toLowerCase()
                                    .replace(/[- ]/, '_');
                                const imageLocations = [];
                                const images = value.images;
                                const sciName = value.sciName;
                                const name = value.name;
                                const category = cat;
                                const invasive = value.invasive;
                                const information = makeInformation(value);

                                async.forEachOf(
                                    images,
                                    function (image, key, callback) {
                                        const form = new FormData();

                                        form.append('name', image.title);
                                        form.append('credits', image.credits);
                                        form.append(
                                            'description',
                                            image.description
                                        );
                                        form.append('speciesId', id + key);
                                        form.append('image', image.imageSource);

                                        const options = {
                                            method: 'post',
                                            headers: {
                                                'Content-Type':
                                                    'multipart/form-data',
                                                Authorization: sessionStorage.getItem(
                                                    'token'
                                                ),
                                            },
                                            body: form,
                                        };

                                        // This line allows the browser to automatically set the boundary between form rows
                                        delete options.headers['Content-Type'];

                                        fetch(
                                            `${APIurl}/catalog/add_image`,
                                            options
                                        )
                                            .then(response => response.json())
                                            .then(data => {
                                                imageLocations.push(data.name);
                                                callback();
                                            })
                                            .catch(err => callback(err));
                                    },
                                    function (err) {
                                        if (err) {
                                            console.log(err);
                                        } else {
                                            const data = JSON.stringify({
                                                name: name,
                                                scientificName: sciName,
                                                category: category,
                                                invasive: invasive,
                                                jsonName: `${parsedName}.json`,
                                                information: information,
                                                pictures: imageLocations,
                                            });

                                            fetch(`${APIurl}/species/add`, {
                                                method: 'post',
                                                headers: {
                                                    'Content-Type':
                                                        'application/json',
                                                    Authorization: sessionStorage.getItem(
                                                        'token'
                                                    ),
                                                },
                                                body: data,
                                            })
                                                .then(response =>
                                                    response.json()
                                                )
                                                .then(data => {
                                                    const finalOptions = {
                                                        method: 'post',
                                                        headers: {
                                                            'Content-Type':
                                                                'application/json',
                                                            Authorization: sessionStorage.getItem(
                                                                'token'
                                                            ),
                                                        },
                                                        body: JSON.stringify({
                                                            imageArray: imageLocations,
                                                        }),
                                                    };
                                                    fetch(
                                                        `${APIurl}/catalog/order_images`,
                                                        finalOptions
                                                    )
                                                        .then(response =>
                                                            response.json()
                                                        )
                                                        .then(data => {
                                                            if (data.error) {
                                                                cb(data.error);
                                                            } else {
                                                                cb();
                                                            }
                                                        });
                                                });
                                        }
                                    }
                                );
                            },
                            function (err) {
                                if (err) {
                                    console.log(err);
                                }
                            }
                        );
                    })
                    .catch(err => console.log(err));
                // Adding any newly created species
            });
    }

    toggleMoveEntryPopup() {
        this.setState({ showMovementPopup: !this.state.showMovementPopup });
    }

    componentDidMount() {
        const helper = (parents, category, tree) => {
            if (parents.length === 0) {
                this.setState({
                    hasNoSubCategories: isBottomCategory(tree[category]),
                });
            } else {
                const firstEle = parents.shift();
                helper(parents, category, tree[firstEle]);
            }
        };
        fetch(`${APIurl}/tree/all`, {
            method: 'GET',
            headers: new Headers({
                Authorization: sessionStorage.getItem('token'),
            }),
        })
            .then(response => response.json())
            .then(response => {
                helper(
                    JSON.parse(JSON.stringify(this.props.categoryParents)),
                    this.props.categoryName,
                    response.tree
                );
            });
    }

    render() {
        return (
            <div className="Main-contents">
                {this.state.showMovementPopup ? (
                    <MoveEntryPopup
                        moveEntry={this.props.confirmMove}
                        thisCategory={this.state.name}
                        containedEntries={this.props.members
                            .concat(this.props.movedSpecies)
                            .concat(this.props.newSpecies)}
                        closePopup={this.toggleMoveEntryPopup}
                    />
                ) : null}
                {this.props.image === undefined ? (
                    <div className="Picture-holder">
                        Primary Image*
                        <button
                            className="Add-image"
                            onClick={() => {
                                this.setState({ popupActive: true });
                            }}
                        >
                            +
                        </button>
                    </div>
                ) : (
                    <div className="Picture-holder">
                        <PictureFrame
                            singleImage={true}
                            source={this.props.image}
                            handleImageClick={() => {
                                this.setState({ popupActive: true });
                            }}
                            handleDeleteClick={this.props.handleDeleteClick}
                        />
                    </div>
                )}
                {this.props.invasiveImage === undefined ||
                    this.props.invasiveImage === `${imageurl}undefined` ? (
                    <div className="invasiveImageHolder">
                        Category Invasive Image
                        <button
                            className="Add-image"
                            onClick={() => {
                                this.setState({ invasivePopup: true });
                            }}
                        >
                            +
                            </button>
                    </div>
                ) : (
                    <div className="invasiveImageHolder">
                        <PictureFrame
                            singleImage={true}
                            invasiveImage={true}
                            source={this.props.invasiveImage}
                            handleImageClick={() => {
                                this.setState({ invasivePopup: true });
                            }}
                            handleDeleteClick={this.props.deleteInvasiveImage}
                        />
                    </div>
                )}
                {this.state.popupActive ? (
                    <Popup
                        contents={
                            new imageWithInfo(
                                'unknown',
                                this.props.image,
                                'unknown',
                                ''
                            )
                        }
                        isAddImage="true"
                        handleSubmitClick={(a, b, c) => {
                            this.props.handlePopupSubmitClick(a, b, c);
                            this.handlePopupCancelClick();
                        }}
                        handleCancelClick={this.handlePopupCancelClick}
                    />
                ) : null}
                {this.state.invasivePopup ? (
                    <Popup
                        contents={
                            new imageWithInfo(
                                'unknown',
                                this.props.invasiveImage,
                                'unknown',
                                ''
                            )
                        }
                        isInvasiveAddImage={true}
                        handleSubmitClick={(a, b, c) => {
                            this.props.handleInvasivePopupSubmit(a, b, c);
                            this.setState({ invasivePopup: false });
                        }}
                        handleCancelClick={this.handlePopupCancelClick}
                    />
                ) : null}
                <div id="Category-info">
                    <span id="Left-input">
                        <label>Category Name</label>
                        <input
                            type="text"
                            value={this.state.name}
                            onChange={this.handleNameChange}
                            readOnly={this.state.superCategory === undefined}
                        />
                    </span>
                    <span id="Right-input">
                        {this.state.superCategory === undefined ? (
                            'No Parent Category'
                        ) : (
                            <div>
                                <label>Parent Category</label>:{' '}
                                {this.state.superCategory}
                            </div>
                        )}
                    </span>
                    {(
                        <div id="Entries-wrapper">
                            <div
                                readOnly={true}
                                id="Entries-section-edit"
                                rows="2"
                                cols="20"
                            >
                                {this.props.members.map(item => {
                                    return (
                                        <p className="memberSpecies" key={item}>
                                            {' '}
                                            {item}{' '}
                                        </p>
                                    );
                                })}
                            </div>
                            {(this.props.members[0] !== undefined &&
                                this.props.members !== []) ||
                                this.state.hasNoSubCategories ? (
                                <div>
                                    <button
                                        className="Entries-section-left-button"
                                        onClick={this.toggleMoveEntryPopup}
                                    >
                                        Move Existing Entry
                                        </button>
                                    <Link
                                        to={{
                                            pathname: '/lsadmin/new_entry',
                                            state: {
                                                prevInfo: {
                                                    name: this.props
                                                        .categoryName,
                                                    image: this.props.image,
                                                    invasiveImage: this.props
                                                        .invasiveImage,
                                                    parentCategories: this.props
                                                        .categoryParents,
                                                },
                                                memberSpecies: this.props
                                                    .memberSpecies,
                                                addedSpecies: this.props
                                                    .addedSpecies,
                                                movedSpecies: this.props
                                                    .movedSpecies,
                                                new: false,
                                            },
                                        }}
                                    >
                                        <button className="Entries-section-right-button">
                                            Create New Entry
                                            </button>
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
                <div className="Bottom-buttons">
                    {(this.state.image !== undefined ||
                        this.props.image !== undefined) &&
                        categoryNameAcceptable(
                            this.state.name,
                            this.state.oldName,
                            this.state.tree
                        ) ? (
                        <Link to="/lsadmin/changes">
                            <button
                                onClick={this.handleSubmitClick}
                                id="Cat-submit-button"
                            >
                                Submit
                                </button>
                        </Link>
                    ) : null}

                    <button
                        id="Cat-cancel-button"
                        onClick={() => {
                            window.history.back();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}

function makeInformation(value) {
    const information = {};
    for (const key in value.otherInfo) {
        if (value.otherInfo[key] !== undefined) {
            information[key] = value.otherInfo[key];
        }
    }
    const parsedInfo = {};
    for (const subCat in information) {
        parsedInfo[subCat] = information[subCat].split('\n');
        if (parsedInfo[subCat][parsedInfo[subCat].length - 1] === '') {
            parsedInfo[subCat].pop();
        }
    }
    return parsedInfo;
}

//                    {this.state.image !== undefined && categoryNameAcceptable(this.state.name, this.state.oldName, tree) && categoryParentAcceptable(this.state.superCategory, this.state.oldName, tree)}
function isBottomCategory(subTree) {
    // If the only things a category has in it are its invasive and uninvasive images and species which are remembered elsewhere, then it is a bottom level category
    return Object.keys(subTree).length <= 2;
}
function getAllCategories(tree) {
    const toReturn = [];
    function helper(subTree) {
        for (let i = 0; i < Object.keys(subTree).length; i++) {
            const ithKey = Object.keys(subTree)[i];
            if (ithKey !== 'picture' && ithKey !== 'invasivePicture') {
                if (!isBottomCategory(subTree[ithKey])) {
                    toReturn.push(ithKey);
                    helper(subTree[ithKey]);
                } else {
                    toReturn.push(ithKey);
                }
            }
        }
    }
    helper(tree);
    return toReturn;
}

function categoryNameAcceptable(name, oldName, tree) {
    if (name === oldName) {
        // Name hasn't changed, it's fine
        return true;
    } else if (name === '') {
        return false;
    } else {
        const allCats = getAllCategories(tree);
        let toReturn = true;
        for (let j = 0; j < allCats.length; j++) {
            if (allCats[j] === name) {
                toReturn = false;
            }
        }
        return toReturn;
    }
}
function categoryParentAcceptable(parentCategory, oldName, tree) {
    if (parentCategory === oldName) {
        return false;
        // You can't have a category be its own parent
    } else if (
        parentCategory === undefined ||
        parentCategory === null ||
        parentCategory === ''
    ) {
        return true;
    } else {
        const allCats = getAllCategories(tree);
        let toReturn = false;
        for (let i = 0; i < allCats.length; i++) {
            if (allCats[i] === parentCategory) {
                toReturn = true;
                // ParentCategory must be a member of allCats
            }
        }
        return toReturn;
    }
}
export { categoryNameAcceptable, categoryParentAcceptable };
export default EditCategory;
