import {
    HashRouter as Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import React from 'react';
import HomePage from './HomePage.js';
import UserAdmin from './UserAdmin.js';
import NewEntry from './NewEntry.js';
import CurrentCatalog from './CurrentCatalog.js';
import ManageCategoriesPage from './ManageCategoriesPage.js';
import EditEntry from './EditEntry.js';
import ReviewEntry from './ReviewEntry.js';
import NewUser from './NewUser.js';
import Login from './Login.js';
import NewCategory from './NewCategory.js';
import EditCategory from './EditCategory.js';
import Log from './Log.js';
import Home from './PublicWebsite/homePage/Home.js';
import Information from './PublicWebsite/informationPage/Information.js';

/*
 *This is where navigation is largely handled. When a new page is added
 *the path to it is added here.
 *"https://chitna.asap.um.maine.edu:4300"
 */
const APIurl = "https://maineaquaticfieldguide.org:36000";
const imageurl = "https://maineaquaticfieldguide.org/lakeStewardsImages/";
//const APIurl = "http://chitna.asap.um.maine.edu:4301";
//const imageurl = "https://chitna.asap.um.maine.edu/elistory/www/LakeStewardsAPI/API/files/liveImages/";

//const APIurl = 'https://maineaquaticfieldguide.org:36000';

// Use privateroute and permission to restrict pages; 0 means the user is a user, 1 means an editor, and 2 an admin
function AppRouter() {
    return (
        // All but login and newuser should be private routes
        <Router>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/information" component={Information} />
                <Route path="/lsadmin" exact component={Login} />
                <PrivateRoute
                    path="/lsadmin/changes"
                    exact
                    component={HomePage}
                    permission={1}
                />
                <PrivateRoute
                    path="/lsadmin/user_admin"
                    component={UserAdmin}
                    permission={2}
                />
                <PrivateRoute
                    path="/lsadmin/catalog"
                    component={CurrentCatalog}
                    permission={1}
                />
                <PrivateRoute
                    path="/lsadmin/new_entry"
                    component={NewEntry}
                    permission={1}
                />
                <Route
                    path="/lsadmin/new_user"
                    component={NewUser}
                    permission={2}
                />
                <PrivateRoute
                    path="/lsadmin/manage_categories"
                    component={ManageCategoriesPage}
                    permission={1}
                />
                <PrivateRoute
                    path="/lsadmin/edit_entry"
                    component={EditEntry}
                    permission={1}
                />
                <PrivateRoute
                    path="/lsadmin/review_entry"
                    component={ReviewEntry}
                    permission={1}
                />
                <PrivateRoute
                    path="/lsadmin/new_category"
                    component={NewCategory}
                    permission={1}
                />
                <PrivateRoute
                    path="/lsadmin/edit_category"
                    component={EditCategory}
                    permission={1}
                />
	    	<PrivateRoute
	    	    path="/lsadmin/log"
	    	    component={Log}
	            permission={1}
	    	/>
            </Switch>
        </Router>
    );
}

function PrivateRoute(props) {
    if (sessionStorage.getItem('token') !== null) {
        return sessionStorage.getItem('permission') >= props.permission ? (
            <Route exact path={props.path} component={props.component} />
        ) : (
            <Redirect to="/lsadmin/changes" exact />
        );
    } else {
        return <Redirect to="/lsadmin" exact />;
    }
}

export default AppRouter;
export { imageurl };
export { APIurl };
