import React from 'react';
import './CategorySection.css';
import CategoryCard from './CategoryCard.js';
import Search from './Search.js';
import Checkbox from './Checkbox.js';

const welcomeTitle = `
Every lake in Maine is a place of wonder and discovery.
`;
const welcomeText = `We welcome you to search a wide array of aquatic FLORA, FAUNA, and OTHER LAKE PHENOMENA and urge you to join us in the ongoing 
 development of this crowd-sourced compendium.`;

export default class CategorySection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFullText: false,
        };
    }

    render() {
        const navOrder = this.props.navigationOrder;
        return (
            <div className={'mid-bar'}>
                {
                    <div className="WelcomeHolder">
                        <div className="WelcomeSection">
                            <h2 className="WelcomeSubTitle">{welcomeTitle}</h2>
                            <p className="WelcomeText">{welcomeText}</p>
                        </div>
                    </div>
                }
                {navOrder.length >= 1 ? (
                    <span>
                        <label
                            className="path-label"
                            onClick={this.props.resetCategories}
                        >
                            All
                        </label>
                        <label>{' > '}</label>
                    </span>
                ) : null}
                <div className="nav-path">
                    {navOrder.map(value => {
                        if (navOrder.indexOf(value) !== navOrder.length - 1) {
                            return (
                                <span>
                                    <label
                                        onClick={() =>
                                            this.props.returnTo(value)
                                        }
                                        className="path-label"
                                    >
                                        {value
                                            .toLowerCase()
                                            .split(' ')
                                            .map(
                                                s =>
                                                    s.charAt(0).toUpperCase() +
                                                    s.substring(1)
                                            )
                                            .join(' ')}
                                    </label>
                                    <label>{' > '}</label>
                                </span>
                            );
                        } else {
                            return (
                                <label className="path-end">
                                    {value
                                        .toLowerCase()
                                        .split(' ')
                                        .map(
                                            s =>
                                                s.charAt(0).toUpperCase() +
                                                s.substring(1)
                                        )
                                        .join(' ')}
                                </label>
                            );
                        }
                    })}
                </div>
                <div
                    className={
                        Object.keys(this.props.tree).length - 2 >= 3 &&
                            navOrder.length !== 0
                            ? 'category-section long'
                            : 'category-section'
                    }
                >
                    {navOrder.length == 0 ? null : (
                        <CategoryCard
                            name={`${'\u2190'} Back`}
                            type="Back"
                            navigate={
                                navOrder.length > 1
                                    ? () =>
                                        this.props.returnTo(
                                            navOrder[navOrder.length - 2]
                                        )
                                    : this.props.resetCategories
                            }
                        />
                    )}
                    {sessionStorage.getItem('categoryTree')
                        ? // Looks horrible, but this parses the first layer of the category tree and iterates through it
                        /*
                         *These should be replaced by some sort of category card, with a sorting method and name passed
                         *Could probably be a generalized version of species card
                         */
                        Object.entries(this.props.tree).map(value => {
                            if (
                                value[0] !== 'picture' &&
                                value[0] !== 'invasivePicture' &&
                                value[0] !== 'Entries'
                            ) {
                                return (
                                    <CategoryCard
                                        firstLayer={navOrder.length < 1}
                                        name={value[0]}
                                        picture={
                                            this.props.tree[value[0]].picture
                                                ? this.props.tree[value[0]]
                                                    .picture
                                                : undefined
                                        }
                                        invasivePicture={
                                            this.props.tree[value[0]]
                                                .invasivePicture
                                                ? this.props.tree[value[0]]
                                                    .invasivePicture
                                                : undefined
                                        }
                                        navigate={this.props.navigate}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })
                        : null}
                </div>

                <div className="ToolDiv">
                    <Checkbox
                        handleCheckChange={this.props.handleCheckChange}
                        checked={this.props.onlyInvasive}
                        lastCat={
                            this.props.navigationOrder[
                            this.props.navigationOrder.length - 1
                            ]
                        }
                    />

                    <Search
                        placeholder="Search by species name"
                        onSearch={this.props.handleSearch}
                    />
                </div>
                <div className="Explanation">
                    <p id="redText"><b> Red </b></p>
                    <p>indicates a species is invasive; </p>
                    <p id="greenText"><b>green</b></p>
                    <p> indicates a native species.</p>
                </div>
            </div>
        );
    }
}
