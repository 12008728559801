import React from 'react';
import './Popup.css';
import PicturePopup from './PicturePopup';
import './PicturePopup.css';

function Popup(props) {
    return (
        <div className="Popup">
            <div className="Inner-popup">
                <p> {props.dialogue} </p>
                {props.isAddImage === 'true' ? (
                    <PicturePopup
                        handleCancelClick={props.handleCancelClick}
                        handleSubmitClick={props.handleSubmitClick}
                        contents={props.contents}
                        highlighted={props.highlighted}
                        pageType={props.pageType}
                    />
                ) : props.isInvasiveAddImage ? (
                    <PicturePopup
                        handleCancelClick={props.handleCancelClick}
                        handleSubmitClick={props.handleSubmitClick}
                        contents={props.contents}
                    />
                ) : props.isPublishing ? null : (
                    <div>
                        <button
                            id="Submit-button"
                            onClick={() => {
                                props.handleSubmitClick(props.speciesName);
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            id="Cancel-button"
                            onClick={props.handleCancelClick}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Popup;
