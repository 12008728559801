import React from 'react';
import { TopCatManSort, RightImage, getImage } from './SortingCategory.js';
import { APIurl } from '../Router.js';
import './SortingCategory.css';

// Categorysystem isn't used anwywhere, catmansystem is used instead?

class CatManSystem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mostRecentChoice: undefined,
            tree: undefined,
            choiceParents: [],
            topCats: ['flora', 'fauna', 'other aquatic curiosities'],
            topCategories: [
                <TopCatManSort
                    handleCatSelection={this.props.handleCatSelection}
                    categoryName={'fauna'}
                    parentCategories={[]}
                    type={this.props.type}
                />,
                <TopCatManSort
                    handleCatSelection={this.props.handleCatSelection}
                    categoryName={'flora'}
                    parentCategories={[]}
                    type={this.props.type}
                />,
                <TopCatManSort
                    handleCatSelection={this.props.handleCatSelection}
                    categoryName={'other aquatic curiosities'}
                    parentCategories={[]}
                    type={this.props.type}
                />,
            ],
        };
        this.updateChoice = this.updateChoice.bind(this);
    }

    updateChoice(choice, parents) {
        this.setState({ mostRecentChoice: choice, choiceParents: parents });
    }

    componentDidMount() {
        const URL = `${APIurl}/tree/all`;
        fetch(URL, {
            method: 'GET',
            headers: new Headers({
                Authorization: sessionStorage.getItem('token'),
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    return response;
                } else {
                    const topLevelCategories = Object.keys(response.tree);
                    this.setState({
                        tree: response.tree,
                        topCats: topLevelCategories,
                        topCategories: [
                            <TopCatManSort
                                handleCatSelection={
                                    this.props.handleCatSelection
                                }
                                categoryName={topLevelCategories[0]}
                                parentCategories={[]}
                                type={this.props.type}
                            />,
                            <TopCatManSort
                                handleCatSelection={
                                    this.props.handleCatSelection
                                }
                                categoryName={topLevelCategories[1]}
                                parentCategories={[]}
                                type={this.props.type}
                            />,
                            <TopCatManSort
                                handleCatSelection={
                                    this.props.handleCatSelection
                                }
                                categoryName={topLevelCategories[2]}
                                parentCategories={[]}
                                type={this.props.type}
                            />,
                        ],
                    });
                }
            });
    }

    render() {
        return (
            <div>
                <div
                    className={
                        this.props.type !== 'selector'
                            ? 'WholeSystem'
                            : 'Selector'
                    }
                >
                    {this.state.tree
                        ? this.state.topCategories.map(x => {
                            return (
                                <TopCatManSort
                                    key={x.props.categoryName}
                                    categoryName={x.props.categoryName}
                                    parentCategories={[]}
                                    switchNewCategoryButton={
                                        this.props.switchNewCategoryButton
                                    }
                                    handleCatSelection={
                                        this.props.handleCatSelection
                                    }
                                    updateSelection={this.updateChoice}
                                    tree={
                                        this.state.tree[x.props.categoryName]
                                    }
                                    type={this.props.type}
                                />
                            );
                        })
                        : null}
                </div>
                <div>
                    <RightImage
                        image={getImage(
                            this.state.mostRecentChoice,
                            this.state.choiceParents,
                            this.state.tree
                        )}
                        mostRecentSelection={this.state.mostRecentChoice}
                        choiceParents={this.state.choiceParents}
                    />
                </div>
            </div>
        );
    }
}
export { CatManSystem };
