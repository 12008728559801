import React from 'react';
import Popup from './Components/Popup.js';
import SideBar from './Components/SideBar.js';
import TopBar from './Components/TopBar.js';
import { Link } from 'react-router-dom';
import './CurrentCatalog.css';
import './HomePage.css';
import { APIurl } from './Router.js';
import xIcon from './Icons/x_icon_clip_art_9385.jpg';
import pencilIcon from './Icons/download.png';

class CurrentCatalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupActive: false,
            warningPopup: false,
            species: [],
            invasiveSpecies: [],
            showingNonInvasive: true,
            speciesName: undefined,
        };
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleInvasiveClick = this.handleInvasiveClick.bind(this);
        this.confirmDeletionProposal = this.confirmDeletionProposal.bind(this);
    }

    handleDeleteClick(e, name, added) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        if (added) {
            this.setState({ warningPopup: true, popupActive: true });
        } else {
            this.setState({ speciesName: name, popupActive: true });
        }
    }

    confirmDeletionProposal(name) {
        const URL = `${APIurl}/species/delete`;
        fetch(URL, {
            method: 'POST',
            headers: new Headers({
                'Content-type': 'application/json',
                Authorization: sessionStorage.getItem('token'),
            }),
            body: JSON.stringify({ name: name }),
        })
            .then(response => response)
            .then(response => {
                if (response.error) {
                    console.log(response);
                } else {
                    window.location.reload();
                }
            });
        this.setState({ popupActive: false });
    }

    handleCancelClick() {
        this.setState({ popupActive: false });
    }

    handleInvasiveClick() {
        this.setState({ showingNonInvasive: !this.state.showingNonInvasive });
    }

    render() {
        return (
            <div style={{ height: '100%' }}>
                {this.state.popupActive ? (
                    this.state.warningPopup ? (
                        <Popup
                            handleCancelClick={this.handleCancelClick}
                            dialogue={"You can't do that you rube"}
                            handleSubmitClick={this.handleCancelClick}
                        />
                    ) : (
                        <Popup
                            handleCancelClick={this.handleCancelClick}
                            handleSubmitClick={this.confirmDeletionProposal}
                            dialogue={`Are you sure you want to delete this species, ${this.state.speciesName}?`}
                            speciesName={this.state.speciesName}
                        />
                    )
                ) : null}
                <TopBar page="Catalog" />
                <SideBar selected="Current Catalog" />
                <div id="Body">
                    {/* <CatManSystem/> */}

                    <CardTable
                        handleDeleteClick={this.handleDeleteClick}
                        species={
                            this.state.showingNonInvasive
                                ? this.state.species
                                : this.state.invasiveSpecies
                        }
                        handleInvasiveClick={this.handleInvasiveClick}
                    />
                </div>
            </div>
        );
    }

    componentDidMount() {
        const URL = `${APIurl}/species/all`;
        fetch(URL, { method: 'GET' })
            .then(results => {
                return results.json();
            })
            .then(data => {
                const invasives = [];
                const content = data.species.map(species => {
                    const foo = {
                        name: species.name,
                        category: species.category,
                        scientificName: species.scientificName,
                        invasive: species.invasive,
                        deleted: species.deleted,
                        modified: species.modified,
                        added: species.added,
                    };
                    if (species.invasive === 1) {
                        invasives.push(foo);
                    }
                    return foo;
                });
                this.setState({ species: content, invasiveSpecies: invasives });
            })
            .catch(e => console.log(`there was an error${e}`));
    }
}

class CardTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.species,
            sortBy: commonNameAlphabetical,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const sorter =
            event.target.value === 'Common name'
                ? commonNameAlphabetical
                : sciNameAlphabetical;
        this.setState({
            sortBy: sorter,
        });
    }

    render() {
        return (
            <div className="Right-side">
                <SortBar
                    handleChange={this.handleChange}
                    handleInvasiveClick={this.props.handleInvasiveClick}
                />
                {createCardTable(
                    this.state.sortBy,
                    this.props.species,
                    this.props.handleDeleteClick
                )}
            </div>
        );
    }
}

// A button to link to the new entry page
function SortBar(props) {
    return (
        <div className="Sorting-bar">
            <label className="Sort-bar-label">Show only invasive:</label>
            <input type="checkbox" onClick={props.handleInvasiveClick} />
            <SortDropdown handleChange={props.handleChange} />
            <Link to="/lsadmin/new_entry">
                <button id="New-entry-button">Propose New Entry</button>
            </Link>
        </div>
    );
}

class SortDropdown extends React.Component {
    render() {
        return (
            <span>
                <label className="Sort-bar-label">Sort by:</label>
                <select
                    id="Sort-by-dropdown"
                    onChange={this.props.handleChange}
                >
                    <option defaultValue value="Common name">
                        Common name
                    </option>
                    <option value="Scientific name">Scientific name</option>
                </select>
            </span>
        );
    }
}

// Sorting stuff ----------------------
function commonNameAlphabetical(a, b) {
    // Returns true or false based on whether a's common name is alphabetically before b
    if (b.name.toLowerCase() > a.name.toLowerCase()) {
        return -1;
    } else {
        return 1;
    }
}
function sciNameAlphabetical(a, b) {
    if (a.scientificName === '' || b.scientificName === '') {
        if (a.scientificName === '') {
            return 1;
        } else {
            return -1;
        }
    } else if (
        b.scientificName.toLowerCase() > a.scientificName.toLowerCase()
    ) {
        return -1;
    } else {
        return 1;
    }
}

function sortBy(orderFunc, switchArray) {
    const result = switchArray.sort(orderFunc);
    return result;
}
//-----------------------------------

/*
 *Takes in an array of objects and returns a table of SpeciesCards with
 *the information in the objects
 */
function createCardTable(sortedBy, contents, deleteHandler) {
    contents = sortBy(sortedBy, contents);
    let bottomStyle;

    const cards = contents.map(function (item, i) {
        if (item.modified === 1) {
            bottomStyle = ' Modified';
        } else if (item.deleted === 1) {
            bottomStyle = ' Deleted';
        } else if (item.added === 1) {
            bottomStyle = ' Added';
        } else {
            bottomStyle = '';
        }
        return (
            <div key={i} className={`Catalog-card`}>
                <SpeciesCard
                    invasive={item.invasive}
                    name={item.name}
                    scientificName={item.scientificName}
                    modified={item.modified}
                    deleted={item.deleted}
                    added={item.added}
                    bottomStyle={bottomStyle}
                    handleDeleteClick={deleteHandler}
                />
            </div>
        );
    });

    return <div className="Cards">{cards}</div>;
}

// The "card" where the name and scientific name of species are shown
class SpeciesCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryCategory: '',
        };
    }

    render() {
        /*
         *To pass information about what species was used to navigate to edit page, use the format
         * to={{pathname: "/edit_entry", state: {foo: foo}}} on the link
         */
        let invasiveStyle;
        this.props.invasive
            ? (invasiveStyle = ' Invasive')
            : (invasiveStyle = '');

        let linkTo;
        this.props.bottomStyle === ''
            ? (linkTo = '/lsadmin/edit_entry')
            : (linkTo = '/lsadmin/review_entry');
        return (
            <Link
                className="Card-link"
                to={{
                    pathname: linkTo,
                    state: {
                        name: this.props.name,
                        deleted: this.props.deleted,
                    },
                }}
            >
                <div className={`Card-contents`}>
                    {this.props.bottomStyle !== '' ? (
                        <label
                            className={`Top-right-label${this.props.bottomStyle}`}
                        >
                            {' '}
                            {this.props.bottomStyle}{' '}
                        </label>
                    ) : (
                        <button
                            className="Delete-button"
                            onClick={e =>
                                this.props.handleDeleteClick(
                                    e,
                                    this.props.name,
                                    this.props.added
                                )
                            }
                        >
                            <img
                                className="Icon-image"
                                src={xIcon}
                                alt="Delete"
                            />
                        </button>
                    )}
                    {this.props.bottomStyle !== '' ? null : (
                        <Link
                            to={{
                                pathname: '/lsadmin/edit_entry',
                                state: { name: this.props.name },
                            }}
                        >
                            <button className="Edit-button">
                                <img
                                    className="Icon-image"
                                    src={pencilIcon}
                                    alt="Edit"
                                />
                            </button>
                        </Link>
                    )}

                    <p className="Name-label">{this.props.name}</p>
                    <p className="Sci-name-label">
                        {this.props.scientificName}
                    </p>
                    <button className={`Info-button ${invasiveStyle}`}>
                        {this.props.invasive
                            ? 'Invasive Species'
                            : 'Native Species'}
                    </button>
                </div>
            </Link>
        );
    }
}
export {
    CardTable,
    SortDropdown,
    createCardTable,
    sortBy,
    commonNameAlphabetical,
    sciNameAlphabetical,
};
export default CurrentCatalog;
