import { Contents } from './NewEntry.js';
import TopBar from './Components/TopBar.js';
import SideBar from './Components/SideBar.js';
import React from 'react';
import { APIurl } from './Router.js';
import { imageurl } from './Router.js';

const async = require('async');
const Diff = require('diff');

class ReviewEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            json: {
                pictures: '',
                name: '',
                'scientific name': '',
                tags: {},
                information: {},
                newSpecies: false,
            },
            highlighted: false,
        };
        this.toggleHighlighted = this.toggleHighlighted.bind(this);
    }

    render() {
        return (
            <div>
                <TopBar page="Review Entry" />
                <SideBar />
                <Contents
                    pageType="Review"
                    images={this.state.json.pictures}
                    name={this.state.json.name}
                    sciName={this.state.json['scientific name']}
                    otherInfo={this.state.json.information}
                    invasive={
                        this.state.json.information.invasive === ''
                            ? true
                            : false
                    }
                    highlighted={this.state.highlighted}
                    highlightedEntries={this.state.highlightedEntries}
                    category={this.state.json.category}
                    toggleHighlighted={this.toggleHighlighted}
                    newSpecies={this.state.newSpecies}
                />
            </div>
        );
    }

    toggleHighlighted(name) {
        if (!this.state.highlightedEntries) {
            this.setHighlightedFields(
                name,
                function(highlightedFields) {
                    this.setState({
                        highlightedEntries: highlightedFields,
                    });
                }.bind(this)
            );
        }
        this.setState({
            highlighted: !this.state.highlighted,
        });
    }

    /*
        This function takes two versions of information and pictures and compares the 
        two to create a set of changes. For the text fields the old version is coming 
        from the JSON files with the information. The new version is coming from the 
        database (As of 4/22/2022 this is a known problem where it is pulling the info 
        from the same table as the live site.). We are using an imported library diff 
        that takes to strings and outputs an array with the differences between them. 
        For the images we are checking if an image was added, removed, or the primary 
        image was changed. For the images, tags are given to make it highlighted.
    */
    setHighlightedFields(speciesName, completionHandler) {
        const state = this.state;
        //console.log(speciesName)
        fetch(`${APIurl}/species/previous`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Name: speciesName },
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    this.setState({ newSpecies: true });
                } else {
                    const oldInfo = data.speciesJson.information;
                    const newKeys = Object.keys(state.json.information);
                    const oldKeys = Object.keys(oldInfo);

                    const changedFields = [];

                    const imageChanges = [];
                    const urlStart = imageurl.length;
                    var imageChanged = false;

                    // Looks to see if any pictures were removed
                    this.state.json.pictures.forEach((newPic, indexNew) => {
                        var found = false;
                        data.speciesJson.pictures.forEach((oldPic, indexOld) => {
                            if(!found && oldPic["image"] === newPic["image"].substring(urlStart)){
                                imageChanges.push({"added": false, "removed": false, "changed": false, "image": newPic});
                                found = true;
                            }
                        })
                        if(!found){ // Added
                            imageChanges.push({"added": true, "removed": false, "changed": false, "image": newPic});
                            imageChanged = true;
                        }
                    })

                    // Looks to see if any pictures were removed
                    data.speciesJson.pictures.forEach(oldPic => {
                        var found = false;
                        this.state.json.pictures.forEach(newPic => {
                            if(!found && oldPic["image"] === newPic["image"].substring(urlStart)){
                                found = true;
                            }
                        })
                        
                        if(!found && oldPic['image']){ // removed
                            oldPic['image'] = imageurl+oldPic['image'];
                            imageChanges.push({"added": false, "removed": true, "changed": false, "image": oldPic});
                            imageChanged = true;
                        }
                    })

                    // old way to do it.
                    // Looks to see if any pictures were removed
                    // data.speciesJson.pictures.forEach((oldPic, indexOld) => {
                    //     var found = false;
                    //     this.state.json.pictures.forEach((newPic, indexNew) => {
                    //         console.log(indexOld, indexNew)
                    //         if(!found && oldPic["image"] === newPic["image"].substring(urlStart)){
                    //             imageChanges.push({"added": false, "removed": false, "changed": false, "image": newPic});
                    //             found = true;
                    //         }
                    //     })
                    //     if(!found){ // Removed
                    //         oldPic["image"] = imageurl+oldPic["image"];
                    //         imageChanges.push({"added": false, "removed": true, "changed": false, "image": oldPic});
                    //         imageChanged = true;
                    //     }
                    // });

                    // // Looks to see if any picturs were added
                    // this.state.json.pictures.forEach(newPic => {
                    //     var found = false;
                    //     data.speciesJson.pictures.forEach(oldPic => {
                    //         if(!found && oldPic["image"] === newPic["image"].substring(urlStart)){
                    //             found = true;
                    //         }
                    //     })
                    //     if(!found){ // Added
                    //         imageChanges.push({"added": true, "removed": false, "changed": false, "image": newPic});
                    //         imageChanged = true;
                    //     }
                    // })

                    //Checks if the primary image was changed
                    if(imageChanges[0]['image']['image'].substring(urlStart) !== data.speciesJson.pictures[0]['image']){
                        imageChanges[0]["changed"] = true;
                        imageChanges.forEach((item, index) => {
                            if(item["image"]["image"].substring(urlStart) === data.speciesJson.pictures[0]['image']){
                                imageChanges[index]["changed"] = true;
                            }
                        })
                        imageChanged = true;
                    }
                    
                    //Checks if all the image info has changed for each image.
                    imageChanges.forEach((changedImage, changedIndex) => {
                        let temp = changedImage.image.image.substring(urlStart)

                        let index = data.speciesJson.pictures.find(image => image.image === temp)
                        if(index !== undefined){
                            let compName = index["name"] === null || index['name'] === undefined ? "" : index['name'];
                            let changedName = changedImage.image["name"] === null || changedImage.image["name"] === undefined ? "" : changedImage.image["name"];
                            let name = Diff.diffWordsWithSpace(compName, changedName)
                            
                            // console.log(index)
                            // console.log(changedImage.image)
                            let compCredit = index["credits"] === null || index['credits'] === undefined ? "" : index['credits'];
                            let changedCredit = changedImage.image["credits"] === null || changedImage.image["credits"] === undefined ? "" : changedImage.image["credits"];
                            let credits = Diff.diffWordsWithSpace(compCredit, changedCredit)

                            let compDescription = index["description"] === null || index['description'] === undefined ? "" : index['description'];
                            let changedDescription = changedImage.image["description"] === null || changedImage.image["description"] === undefined ? "" : changedImage.image["description"];
                            let description = Diff.diffWordsWithSpace(compDescription, changedDescription)
                            
                            imageChanges[changedIndex].image["name"] = name;
                            imageChanges[changedIndex].image["credits"] = credits;
                            imageChanges[changedIndex].image["description"] = description;

                            if(name.length > 1 || credits.length > 1 || description > 1){
                                imageChanged = true;
                            }
                        }
                    });

                    // If there were any changes at all
                    if(imageChanged){
                        changedFields["images"] = imageChanges
                    }

                    //This handles the info fields
                    async.forEachOf(
                        newKeys,
                        function(key, index, callback) {
                            // Checking for any new fields
                            if (!oldKeys.includes(key)) {
                                changedFields.push({"key": key, "changed": null});
                            }
                            if (!oldInfo[key]) {
                                changedFields.push({"key": key, "changed": null});
                                callback();
                            }
                            if(oldInfo[key] !== undefined){
                                infoObjectsEqual(
                                    state.json.information[key],
                                    oldInfo[key],
                                    function(areEqual) {
                                        if (!areEqual) {
                                            // Uses Diff library to make a array with changes between two strings it is given
                                            let changed = Diff.diffWordsWithSpace(oldInfo[key].toString(), state.json.information[key])
                                            changedFields[key] =  changed;
                                        }
                                        callback();
                                    }
                                );
                            }else{
                                // This handles any field that was empty and then filled in the revise state
                                // Uses Diff library to make a array with changes between two strings it is given
                                let changed = Diff.diffWordsWithSpace("", state.json.information[key])
                                changedFields[key] =  changed;
                            }
                        },
                        // Adds all changed field names to changedFields array
                        function(err) {
                            if (state.json.name !== data.speciesJson.name) {
                                let changed = Diff.diffWordsWithSpace(data.speciesJson.name, state.json.name)
                                changedFields['Name'] = changed;
                            }

                            if (
                                state.json['scientific name'] !==
                                data.speciesJson['scientific name']
                            ) {
                                if(!(data.speciesJson['scientific name'] === undefined && state.json['scientific name'] === "")){
                                    let changed = Diff.diffWordsWithSpace(data.speciesJson['scientific name'] === undefined ? "" : data.speciesJson['scientific name'], state.json['scientific name'])
                                    changedFields['Scientific Name'] = changed;
                                }
                            }

                            if (
                                !data.speciesJson.tags.includes(
                                    state.json.category
                                )
                            ) {
                                let changed = Diff.diffLines(data.speciesJson.tags[0], state.json.category)
                                changedFields['Category'] = changed;
                            }

                            // Checking whether status as native/invasive has changed
                            if (
                                Object.keys(
                                    data.speciesJson.information
                                ).includes('Invasive') !==
                                Object.keys(state.json.information).includes(
                                    'invasive'
                                )
                            ) {
                                changedFields['Invasive'] = [];
                            }
                            completionHandler(changedFields);
                        }
                    );
                }
            });
    }


    componentDidMount() {
        let removedSpecies = false;
        if (this.props.location.state && this.props.location.state.changeType === "Removal") {
            removedSpecies = true;
        }

        fetch(`${APIurl}/species/get`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: this.props.location.state.name, isAdmin: true }),
        })
            .then(results => {
                return results.json();
            })
            .then(data => {
                if (data.species !== undefined) {
                    const realPics = [];
                    for (const pic in data.species.pictures) {
                        if (
                            data.species.pictures[pic] !== null &&
                            (!data.species.pictures[pic].removed ||
                            removedSpecies)
                        ) {
                            realPics.push(data.species.pictures[pic]);
                        }
                    }
                    data.species.pictures = realPics;

                    const information = data.species.information;
                    for (const subCat in information) {
                        let infoString = '';
                        for (
                            let item = 0;
                            item < information[subCat].length;
                            item++
                        ) {
                            infoString += `${information[subCat][item]}\n`;
                        }
                        information[subCat] = infoString;
                    }
                    data.species.information = information;

                    this.setState({ json: data.species });
                }
            })
            .catch(err => console.log(err));
    }
}

// Compares two one layer deep objects; returns true or false based on equivalence
function infoObjectsEqual(object1, object2, callback) {
    object1 = object1.split('\n');
    object1.pop();
    if (Object.keys(object1).length !== Object.keys(object2).length) {
        callback(false);
    } else {
        let foundMatches = 0;
        async.forEachOf(
            object1,
            function(item, key, callback) {
                async.forEachOf(
                    object2,
                    function(secondItem, secondKey, innerCallback) {
                        if (item === secondItem) {
                            foundMatches++;
                        }
                        innerCallback();
                    },
                    function(err) {
                        callback();
                    }
                );
            },
            function(err) {
                if (foundMatches === Object.keys(object1).length) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
        );
    }
}

export default ReviewEntry;
