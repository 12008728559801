import React from 'react';
import { imageurl } from '../../Router.js';
import './SpeciesCard.css';

// The cards that will display simplified species information (name, image)

class SpeciesCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            speciesName: this.props.name,
            image: this.props.image,
        };
    }

    render() {
        const name = this.props.speciesName;
        return (
            <a
                href={`/#/information?name=${name}`}
            >
                <div
                    className={
                        this.props.invasive
                            ? 'card invasive'
                            : 'card non-invasive'
                    }
                >
                    {this.props.image ? (
                        <div className="image-holder">
                            <img
                                className="card-image"
                                src={`${imageurl}${this.props.image.url}`}
                            />
                        </div>
                    ) : null}
                    <p
                        className={
                            this.props.speciesName.length < 20
                                ? 'card-label'
                                : 'long-card-label'
                        }
                    >
                        {this.props.speciesName}
                    </p>
                    <p
                        className={
                            this.props.scientificName.length < 30
                                ? 'scientific-name'
                                : 'long-sci-card-label'
                        }
                    >
                        {this.props.scientificName}
                    </p>
                </div>
            </a>
        );
    }
}

export default SpeciesCard;
