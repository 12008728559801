import React from 'react';
import SideBar from './Components/SideBar.js';
import TopBar from './Components/TopBar.js';
import './UserAdmin.css';
import Popup from './Components/Popup.js';
import { APIurl } from './Router.js';
import { removeTimeStamp } from './HomePage.js';

class UserAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
        };
    }

    render() {
        return (
            <div>
                <TopBar page="User Admin" />
                <SideBar selected="User Admin" />
                <Content contents={this.state.users} />
            </div>
        );
    }

    componentDidMount() {
        fetch(`${APIurl}/user/all`, {
            method: 'GET',
            headers: { Authorization: sessionStorage.getItem('token') },
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                const stateUsers = data.users.map(users => {
                    let permission;
                    switch (users.role) {
                    case 0:
                        permission = 'User';
                        break;
                    case 1:
                        permission = 'Editor';
                        break;
                    case 3:
                        permission = 'Owner';
                        break;
                    default:
                        permission = 'Administrator';
                        break;
                    }
                    return {
                        name: users.name,
                        email: users.email,
                        permission: permission,
                        dateAdded: users.dateAdded,
                    };
                });
                this.setState({ users: stateUsers });
            });
    }
}

// A[0] is username, a[1] is email, a[2] is permission, a[3] is date.  Replace when the data structure of the set of users changes.
function usernameAlphabetical(a, b) {
    // Returns true or false based on whether a's username is alphabetically before b
    if (b.name.toLowerCase() > a.name.toLowerCase()) {
        return -1;
    } else {
        return 1;
    }
}
function emailAlphabetical(a, b) {
    if (b.email.toLowerCase() > a.email.toLowerCase()) {
        return -1;
    } else {
        return 1;
    }
}
function permissionLevel(a, b) {
    if (b.permission.toLowerCase() === a.permission.toLowerCase()) {
        return usernameAlphabetical(a, b); // If permissionLevels are equal, go by username
    } else if (b.permission.toLowerCase() > a.permission.toLowerCase()) {
        return -1;
    } else {
        return 1;
    }
}
function dateAdded(a, b) {
    // A and b are 1d arrays
    if (a.dateAdded <= b.dateAdded) {
        return 1;
    } else {
        return -1;
    }
}
function sortBy(orderFunc, switchArray) {
    const result = switchArray.sort(orderFunc);
    return result;
}

function finalizeUserRemoval(username) {
    fetch(`${APIurl}/user/delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ name: username }),
    })
        .then(response => response.json())
        .then(response => window.location.reload());
}

function isNewUser(dateAdded) {
    const currentDate = Date.now();
    const userAdded = Date.parse(dateAdded);
    return currentDate - userAdded < 1209600000;
}
class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contents: [],
            pupupActive: false,
            popupInfo: { name: '', changedTo: '' },
            userRemoval: undefined,
        };
        this.bUser = this.bUser.bind(this);
        this.bEmail = this.bEmail.bind(this);
        this.permissionLevel = this.permissionLevel.bind(this);
        this.dateAdded = this.dateAdded.bind(this);
        this.handlePermissionChange = this.handlePermissionChange.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ contents: nextProps.contents });
    }

    bUser() {
        this.setState({
            contents: sortBy(usernameAlphabetical, this.state.contents),
        });
    }

    bEmail() {
        this.setState({
            contents: sortBy(emailAlphabetical, this.state.contents),
        });
    }

    permissionLevel() {
        this.setState({
            contents: sortBy(permissionLevel, this.state.contents),
        });
    }

    dateAdded() {
        this.setState({
            contents: sortBy(dateAdded, this.state.contents),
        });
    }

    handlePermissionChange(name, event) {
        let changeTarget;
        if (event.target.value == 0) {
            changeTarget = 'user';
        } else if (event.target.value == 1) {
            changeTarget = 'editor';
        } else if (event.target.value == 2) {
            changeTarget = 'administrator';
        } else {
            changeTarget = 'owner';
        }

        const newInfo = { name: name, changedTo: changeTarget };
        this.setState({
            popupActive: true,
            popupInfo: newInfo,
        });
    }

    handleCancelClick() {
        this.setState({ popupActive: false });
        window.location.reload();
        // Simple way to reset the changed permission levels
    }

    handleSubmitClick() {
        const name = this.state.popupInfo.name;
        let role;

        if (this.state.popupInfo.changedTo === 'user') {
            role = 0;
        } else if (this.state.popupInfo.changedTo === 'editor') {
            role = 1;
        } else {
            role = 2;
        }

        const data = JSON.stringify({ name: name, role: role });

        fetch(`${APIurl}/user/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token'),
            },
            body: data,
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.setState({ popupActive: false });
            });
    }

    render() {
        const foo = this.handlePermissionChange;
        const rows = this.state.contents.map((item, i) => {
            let dropdownContents;
            if (item.permission === 'User') {
                dropdownContents = (
                    <select
                        defaultValue={0}
                        id="User-dropdown"
                        onChange={event => foo(item.name, event)}
                    >
                        <option value={0}>User</option>
                        <option value={1}>Editor</option>
                        <option value={2}>Administrator</option>
                    </select>
                );
            } else if (item.permission === 'Editor') {
                dropdownContents = (
                    <select
                        defaultValue={1}
                        id="User-dropdown"
                        onChange={event => foo(item.name, event)}
                    >
                        <option value={1}>Editor</option>
                        <option value={0}>User</option>
                        <option value={2}>Administrator</option>
                    </select>
                );
            } else if (item.permission === 'Administrator') {
                sessionStorage.getItem('permission') == 3
                    ? (dropdownContents = (
                        <select
                            defaultValue={2}
                            id="User-dropdown"
                            onChange={event => foo(item.name, event)}
                        >
                            <option value={2}>Administrator</option>
                            <option value={0}>User</option>
                            <option value={1}>Editor</option>
                        </select>
                    ))
                    : (dropdownContents = 'Administrator');
            } else {
                dropdownContents = 'Owner';
            }
            const entry = [
                <tr>
                    <td>
                        {isNewUser(item.dateAdded) ? (
                            <p className="newUserTag"> New </p>
                        ) : null}{' '}
                        {item.name}
                    </td>
                    <td>{item.email}</td>
                    <td>{dropdownContents}</td>
                    <td>{removeTimeStamp(item.dateAdded)}</td>
                    <td>
                        {item.permission === 'Owner' ||
                        (item.permission === 'Administrator' &&
                            sessionStorage.getItem('permission') < 3) ? null : (
                                <button
                                    onClick={() =>
                                        this.setState({ userRemoval: item.name })
                                    }
                                >
                                Remove
                                </button>
                            )}
                    </td>
                </tr>,
            ];
            return entry;
        });
        return (
            <div>
                {this.state.popupActive ? (
                    <Popup
                        dialogue={`Are you sure you want to change ${
                            this.state.popupInfo.name
                        }${
                            this.state.popupInfo.changedTo === 'user'
                                ? ' to a '
                                : ' to an '
                        }${this.state.popupInfo.changedTo}?`}
                        handleCancelClick={this.handleCancelClick}
                        handleSubmitClick={this.handleSubmitClick}
                    />
                ) : null}
                {this.state.userRemoval === undefined ? null : (
                    <Popup
                        dialogue={`Are you sure you want to remove the user: ${
                            this.state.userRemoval
                        }`}
                        handleCancelClick={() =>
                            this.setState({ userRemoval: undefined })
                        }
                        handleSubmitClick={() => {
                            finalizeUserRemoval(this.state.userRemoval);
                            this.setState({ userRemoval: undefined });
                        }}
                    />
                )}
                <div className="User-table-holder">
                    <table className="User-table">
                        <th>
                            Username <span> &nbsp;&nbsp;&nbsp; </span>
                            <button onClick={this.bUser}> sort by</button>
                        </th>

                        <th>
                            Email <span> &nbsp;&nbsp;&nbsp; </span>
                            <button onClick={this.bEmail}> sort by </button>
                        </th>
                        <th>
                            Permission <span> &nbsp;&nbsp;&nbsp; </span>
                            <button onClick={this.permissionLevel}>
                                {' '}
                                sort by{' '}
                            </button>
                        </th>
                        <th>
                            Date Added <span> &nbsp;&nbsp;&nbsp; </span>
                            <button onClick={this.dateAdded}> sort by </button>
                        </th>
                        <th>Remove User</th>
                        {rows}
                    </table>
                </div>
            </div>
        ); // Instead of contents it should start sorted by username
    }
}
export default UserAdmin;
