//A class defining the traits a picture should have

class PictureFormat extends Object {
  constructor(title, author, description, url) {
    super();
    this.title = title;
    this.author = author;
    this.description = description;
    this.url = url;
  }
}

export default PictureFormat;
