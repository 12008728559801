import React from 'react';
import './Checkbox.css';

export default class Checkbox extends React.Component {
    render() {
        return (
            <div
                className={
                    this.props.checked
                        ? 'check-div invasive-check'
                        : 'check-div non-invasive-check'
                }
                onClick={this.props.handleCheckChange}
            >
                {this.props.checked ? (
                    <input className="check" type="checkbox" checked></input>
                ) : (
                    <input className="check" type="checkbox"></input>
                )}
                <label>
                    {this.props.lastCat !== undefined
                        ? `Show ${ 
                          this.props.lastCat
                              .toLowerCase()
                              .split(' ')
                              .map(
                                  s =>
                                      s.charAt(0).toUpperCase() + s.substring(1)
                              )
                              .join(' ') 
                          } Invasives`
                        : 'Show All Invasives'}
                </label>
            </div>
        );
    }
}
