import React from 'react';
import './Footer.css';

const fullText1 = `The current catalog is still far from complete ~ with your help, the catalog 
 will be continually updated with new information and photos, and expanded to include many new phenomena. 
 The app is now available as a mobile app in the `;
const fullText2 = `Do you have a photo or information to add to the existing catalog or a suggestion for expanding 
 the existing new phenomena for inclusion in the catalog? Please use `;
const fullText3 = ` to share your ideas and/or suggested new content. Have you found something suspicious? 
 Please submit a picture and some information about your find using `;
const fullText4 = `FOR COURTESY BOAT INSPECTION PERSONNEL ONLY: Please click `;
const fullText5 = ` to submit photos of specimens removed from boats and/or gear 
 during a boat inspection.  If the suspicious specimen was found growing live in 
 the waterbody and not associated with a current boat launch event, please use the submission form link in the section above.  
Thank you for your service, and your efforts to help protect the lakes of Maine!`;
const fullText6 = ` If you have feedback on or comments about the Maine Field Guide to Aquatic Phenomena, please select `;
const Footer = props => {
    return (
        <div className="Footer">
            <div>
                <p className="WelcomeText">{fullText1}</p>
                <a
                    className="StewardsLink"
                    target="_blank"
                    href={
                        'https://apps.apple.com/us/app/maine-aquatic-phenomena/id1454033609'
                    }
                >
                    Apple Store
                </a>
                <p className="WelcomeText">, and the </p>
                <a
                    className="StewardsLink"
                    target="_blank"
                    href={
                        'https://play.google.com/store/apps/details?id=com.asap.aquatic'
                    }
                >
                    Google Play Store.
                </a>
	    </div>
	    <div><p/></div>
	    <div>
                <p className="WelcomeText">{fullText2}</p>
                <a
                    className="StewardsLink"
                    target="_blank"
                    href={
                        'https://www.lakestewardsofmaine.org/aquatic-phenomena-feedback-form/'
                    }
                >
                    this form
                </a>
                <p className="WelcomeText">{fullText3}</p>
                <a
                    className="StewardsLink"
                    target="_blank"
                    href={
                        'https://www.lakestewardsofmaine.org/aquatic-phenomena-photo-submission-form/'
                    }
                >
                    this form.
                </a>
                <p className="WelcomeText">{fullText6}</p>
                <a
                    className="StewardsLink"
                    target="_blank"
                    href="https://www.lakestewardsofmaine.org/aquatic-phenomena-feedback-form/">
                    this form.

                </a>
            </div>
            <div><p /></div>
            <div>
                <p className="WelcomeText">{fullText4}</p>
                <a
                    className="StewardsLink"
                    target="_blank"
                    href={'https://www.lakestewardsofmaine.org/cbi-submission-form/'}>here</a>

                <p className="WelcomeText">{fullText5}</p>
            </div>
        </div>
    );
};

export default Footer;
