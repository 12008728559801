import React from 'react';
import SideBar from './Components/SideBar.js';
import TopBar from './Components/TopBar.js';
import './ManageCategoriesPage.css';
import { Link } from 'react-router-dom';
// Import CategorySystem from './Components/CategorySystem'
import { CatManSystem } from './Components/CategorySystem';
import { APIurl } from './Router.js';
import './Components/SortingCategory.css';

class ManageCategoriesPage extends React.Component {
    render() {
        return (
            <div>
                <TopBar page="Manage Categories" />
                <SideBar selected="Manage Categories" />
                <MainPage />
            </div>
        );
    }

    componentWillMount() {
        const URL = `${APIurl}/tree/all`;
        fetch(URL, {
            method: 'GET',
            headers: new Headers({
                Authorization: sessionStorage.getItem('token'),
            }),
        });
    }
}
// Var highestLvlCategories =

// OpenCategories={highestLvlCategories}
class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showNewCatButton: false,
        };
        this.switchNewCategoryButton = this.switchNewCategoryButton.bind(this);
    }

    switchNewCategoryButton(tf, name) {
        /*
         *Tf is whether the selected category has subcategories or not
         *name is the selected categories
         */
        this.setState({ showNewCatButton: tf, currentSelection: name });
    }

    render() {
        return (
            <div className="Home-body">
                <CatManSystem
                    switchNewCategoryButton={this.switchNewCategoryButton}
                />
                {this.state.showNewCatButton ? (
                    <Link
                        to={{
                            pathname: '/lsadmin/new_category',
                            state: {
                                prevInfo: {
                                    categoryName: undefined,
                                    superCategory: this.state.currentSelection,
                                },
                            },
                        }}
                    >
                        {' '}
                        <button className="newCategory"> New Category</button>
                    </Link>
                ) : null}
            </div>
        );
    }
} // <button onClick={() => this.setState({newCategoryPopup: ! this.state.newCategoryPopup})}>New Category</button>
// At some point I should delete the unused code for category popup

export default ManageCategoriesPage;
