import { Contents, imageWithInfo } from './NewEntry.js';
import TopBar from './Components/TopBar.js';
import SideBar from './Components/SideBar.js';
import { APIurl } from './Router.js';
import { imageurl } from './Router.js';
import React from 'react';

const async = require('async');

class EditEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalPictures: [],
            invasive: false,
            pictures: [],
            originalName: '',
            name: '',
            family: '',
            sciName: '',
            information: {},
            category: '',
            classification: '',
            details: '',
            subclass: '',
        };
        this.handleEditSubmission = this.handleEditSubmission.bind(this);
        this.handleImagesChange = this.handleImagesChange.bind(this);
        this.handleInfoChange = this.handleInfoChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSciNameChange = this.handleSciNameChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.formatImages = this.formatImages.bind(this);
        this.handleInvasiveChange = this.handleInvasiveChange.bind(this);
    }

    render() {
        return (
            <div>
                <TopBar page="Edit Entry" />
                <SideBar />
                <Contents
                    pageType="Edit"
                    images={this.state.pictures}
                    name={this.state.name}
                    sciName={this.state.sciName}
                    // NEED TO IMPLEMENT TAGS
                    invasive={this.state.invasive}
                    category={this.state.category}
                    otherInfo={this.state.information}
                    handleImagesChange={this.handleImagesChange}
                    handleEditSubmission={this.handleEditSubmission}
                    handleNameChange={this.handleNameChange}
                    handleSciNameChange={this.handleSciNameChange}
                    handleCategoryChange={this.handleSciNameChange}
                    handleInvasiveChange={this.handleInvasiveChange}
                    handleInfoChange={this.handleInfoChange}
                />
            </div>
        );
    }

    handleInfoChange(category, event) {
        const newOtherInfo = this.state.information;
        newOtherInfo[category] = event.target.value;
        this.setState({ information: newOtherInfo });
    }

    handleNameChange(e) {
        this.setState({ name: e.target.value });
    }

    handleSciNameChange(e) {
        this.setState({ sciName: e.target.value });
    }

    handleCategoryChange(newCat) {
        this.setState({ category: newCat });
    }

    handleInvasiveChange() {
        this.setState({ invasive: !this.state.invasive });
    }

    handleImagesChange(i) {
        this.setState({ pictures: i });
    }

    formatImages(imageArray) {
        const foo = imageArray.map(value => {
            console.log(value)
            return new imageWithInfo(
                value['name'],
                value.image,
                value['credits'],
                value['description']
            );
        });
        return foo;
    }

    handleEditSubmission(infoBlob) {
        const jsonData = {
            name: infoBlob.name,
            'scientific name': infoBlob['sciName'],
            information: infoBlob.otherInfo,
        };

        const parsedInfo = {};
        for (const subCat in infoBlob.otherInfo) {
            parsedInfo[subCat] = infoBlob.otherInfo[subCat].split('\n');
            if (parsedInfo[subCat][parsedInfo[subCat].length - 1] === '') {
                parsedInfo[subCat].pop();
            }
        }
        jsonData.information = parsedInfo;
        //console.log(infoBlob);

        const deletedImages = [];
        const addedImages = [];
        const moddedImages = [];
        const ogImages = this.formatImages(this.state.originalPictures);
        const newImages = infoBlob.images;
        let id;
        const ogName = this.state.originalName;
        const category = infoBlob.category;
        const invasive = infoBlob.invasive;
        console.log(infoBlob);
        console.log(invasive);
        const idOptions = {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'multipart/form-data',
                Authorization: sessionStorage.getItem('token'),
                name: ogName,
            }),
        };

        fetch(`${APIurl}/species/id`, idOptions)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    console.log(data);
                } else {
                    id = data.speciesId;
                    const urlStart = imageurl.length;

                    for (const i in newImages) {
                        let found = false;
                        for (const j in ogImages) {
                            if (
                                ogImages[j].imageSource ===
                                    newImages[i].image ||
                                ogImages[j].imageSource ===
                                    newImages[i].imageSource
                            ) {
                                if (
                                    ogImages[j].title !== newImages[i].name ||
                                    ogImages[j].credits !==
                                        newImages[i].credits ||
                                    ogImages[j].description !==
                                        newImages[i].description
                                ) {
                                    moddedImages.push(newImages[i]);
                                }
                                found = true;
                            }
                        }
                        if (!found) {
                            addedImages.push(newImages[i]);
                        }
                    }
                    for (const j in ogImages) {
                        let found = false;
                        for (const i in newImages) {
                            if (
                                ogImages[j].imageSource ===
                                    newImages[i].image ||
                                ogImages[j].imageSource ===
                                    newImages[i].imageSource
                            ) {
                                found = true;
                            }
                        }
                        if (!found) {
                            deletedImages.push(ogImages[j]);
                        }
                    }

                    // Marking any deleted images as such
                    async.forEachOf(
                        deletedImages,
                        function(image, key, callback) {
                            const options = {
                                method: 'GET',
                                headers: new Headers({
                                    'Content-Type': 'multipart/form-data',
                                    Authorization: sessionStorage.getItem(
                                        'token'
                                    ),
                                    url: image.imageSource.substring(urlStart),
                                }),
                            };

                            fetch(`${APIurl}/catalog/delete_image`, options)
                                .then(response => response.json())
                                .then(data => {
                                    if (data.error) {
                                        callback(data.error);
                                    } else {
                                        callback();
                                    }
                                });
                        },
                        function(err) {
                            if (err) {
                                console.log(`error was${err}`);
                            } else {
                                // Adding any new images
                                async.forEachOf(
                                    addedImages,
                                    function(picture, key, innerCallback) {
                                        const form = new FormData();
                                        if (!picture.title) {
                                            picture.title = 'unknown';
                                        }
                                        if (!picture.credits) {
                                            picture.credits = 'unknown';
                                        }
                                        if (!picture.description) {
                                            picture.description = '';
                                        }

                                        form.append('name', picture.title);
                                        form.append('credits', picture.credits);
                                        form.append(
                                            'description',
                                            picture.description
                                        );
                                        form.append('speciesId', id);
                                        form.append(
                                            'image',
                                            picture.imageSource
                                        );

                                        const options = {
                                            method: 'post',
                                            headers: {
                                                'Content-Type':
                                                    'multipart/form-data',
                                                Authorization: sessionStorage.getItem(
                                                    'token'
                                                ),
                                            },
                                            body: form,
                                        };
                                        delete options.headers['Content-Type'];

                                        fetch(
                                            `${APIurl}/catalog/add_image`,
                                            options
                                        )
                                            .then(response => response.json())
                                            .then(data => {
                                                if (data.error) {
                                                    innerCallback(data.error);
                                                } else {
                                                    newImages.splice(
                                                        newImages.indexOf(
                                                            picture
                                                        ),
                                                        1,
                                                        new imageWithInfo(
                                                            null,
                                                            `${imageurl}${data.name}`,
                                                            null,
                                                            null
                                                        )
                                                    );
                                                    innerCallback();
                                                }
                                            });
                                    },
                                    function(err) {
                                        // Calling actual update endpoint
                                        if (err) {
                                            console.log(err);
                                        } else {
                                            async.forEachOf(
                                                moddedImages,
                                                function(
                                                    picture,
                                                    key,
                                                    innestCallback
                                                ) {
                                                    const options = {
                                                        method: 'post',
                                                        headers: {
                                                            'Content-Type':
                                                                'application/json',
                                                            Authorization: sessionStorage.getItem(
                                                                'token'
                                                            ),
                                                        },
                                                        body: JSON.stringify({
                                                            url: picture.imageSource.substring(
                                                                urlStart
                                                            ),
                                                            title:
                                                                picture.title,
                                                            credits:
                                                                picture.credits,
                                                            description:
                                                                picture.description,
                                                        }),
                                                    };
                                                    fetch(
                                                        `${APIurl}/catalog/modify_image`,
                                                        options
                                                    )
                                                        .then(results => {
                                                            return results.json();
                                                        })
                                                        .then(data => {
                                                            if (data.error) {
                                                                innestCallback(
                                                                    data.error
                                                                );
                                                            } else {
                                                                innestCallback();
                                                            }
                                                        });
                                                },
                                                function(err) {
                                                    if (err) {
                                                        console.log(err);
                                                    } else {
                                                        const options = {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type':
                                                                    'application/json',
                                                                Authorization: sessionStorage.getItem(
                                                                    'token'
                                                                ),
                                                            },
                                                            body: JSON.stringify(
                                                                {
                                                                    jsonFile: jsonData,
                                                                    oldName: ogName,
                                                                    category: category,
                                                                    invasive: invasive,
                                                                }
                                                            ),
                                                        };
                                                        fetch(
                                                            `${APIurl}/species/update`,
                                                            options
                                                        ).then(function() {
                                                            const foo = [];
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                newImages.length;
                                                                i++
                                                            ) {
                                                                const badString = imageurl;
                                                                const url = newImages[
                                                                    i
                                                                ][
                                                                    newImages[i]
                                                                        .imageSource
                                                                        ? 'imageSource'
                                                                        : 'image'
                                                                ].substring(
                                                                    badString.length,
                                                                    newImages[
                                                                        i
                                                                    ][
                                                                        newImages[
                                                                            i
                                                                        ]
                                                                            .imageSource
                                                                            ? 'imageSource'
                                                                            : 'image'
                                                                    ].length
                                                                );
                                                                foo[i] = url;
                                                            }
                                                            fetch(
                                                                `${APIurl}/catalog/order_images`,
                                                                {
                                                                    method:
                                                                        'POST',
                                                                    headers: {
                                                                        'Content-Type':
                                                                            'application/json',
                                                                        Authorization: sessionStorage.getItem(
                                                                            'token'
                                                                        ),
                                                                    },
                                                                    body: JSON.stringify(
                                                                        {
                                                                            imageArray: foo,
                                                                        }
                                                                    ),
                                                                }
                                                            );
                                                        });
                                                    }
                                                }
                                            );
                                        }
                                    }
                                );
                            }
                        }
                    );
                }
            });
    }

    componentDidMount() {
        const isDeletion = this.props.location.state.deleted;
        fetch(`${APIurl}/species/get`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: this.props.location.state.name,
                isAdmin: true,
            }),
        })
            .then(results => {
                return results.json();
            })
            .then(data => {
                if (data.species !== undefined) {
                    const information = data.species.information;
                    for (const subCat in information) {
                        let infoString = '';
                        for (
                            let item = 0;
                            item < information[subCat].length;
                            item++
                        ) {
                            infoString += `${information[subCat][item]}\n`;
                        }
                        information[subCat] = infoString;
                    }
                    data.species.information = information;

                    const realPics = [];
                    for (const pic in data.species.pictures) {
                        if (!data.species.pictures[pic].removed || isDeletion) {
                            realPics.push(data.species.pictures[pic]);
                        }
                    }

                    const isInvasive =
                        data.species.information.invasive === '' ? true : false;
                    this.setState({
                        name: data.species.name,
                        sciName: data.species['scientific name'],
                        information: data.species.information,
                        originalPictures: realPics,
                        pictures: realPics,
                        category: data.species.category,
                        originalName: data.species.name,
                        invasive: isInvasive,
                    });
                }
            })
            .catch(err => console.log(err));
    }
}

export default EditEntry;
