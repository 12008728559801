import React from 'react';
import './Popup.css';
import './../UserAdmin.css';
import { Link } from 'react-router-dom';

function ConfirmationPopup(props) {
    return (
        <div className="Popup">
            <div className="NewUserPopup">
                Confirm: Create an account associated to email: {props.email}
                <Link to={{ pathname: '/lsadmin' }}>
                    <button id="smallerButton" onClick={props.confirm}>
                        Confirm
                    </button>
                </Link>
                <button id="smallerButton" onClick={props.closePopup}>
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default ConfirmationPopup;
