import React from 'react';
import { Link } from 'react-router-dom';
import { imageurl } from '../Router.js';
import './SortingCategory.css';
import '../ManageCategoriesPage.css';

// Something needs to store the information of how the category position has been navigated. Other page components depend on that.

// The way I've done what follows isn't exactly optimal. There are two copies of everthing. Don't not change both by acccident

class TopCatManSort extends React.Component {
    constructor(props) {
        super(props);
        this.maker = this.maker.bind(this);
        this.updateCategoryRendering = this.updateCategoryRendering.bind(this);
        this.makeChildren = this.makeChildren.bind(this);
        this.getSubCategories = this.getSubCategories.bind(this);
        this.state = {
            isOpened: false,
            categoryName: this.props.categoryName,
            parentCategories: this.props.parentCategories,
            tree: this.props.tree,
        };
    }

    hasSubCategories = () => {
        let hasKids = false;
        for (const key in this.state.tree) {
            if (
                key !== 'Entries' &&
                key !== 'picture' &&
                key !== 'invasivePicture'
            ) {
                hasKids = true;
            }
        }
        return hasKids;
    };

    getSubCategories = () => {
        if (!this.state.isOpened) {
            return [];
        } else {
            const subCats = [];
            const parents = [...this.state.parentCategories];
            parents.push(this.state.categoryName);
            for (const key in this.state.tree) {
                if (key !== 'invasivePicture') {
                    const infoObj = {
                        name: key,
                        children: this.props.tree[key],
                        parents: parents,
                    };
                    subCats.push(infoObj);
                }
            }

            return subCats;
        }
    };

    updateCategoryRendering = () => {
        this.props.updateSelection(
            this.state.categoryName,
            this.state.parentCategories
        );
        this.setState({ isOpened: !this.state.isOpened });
        if (this.hasSubCategories()) {
            this.props.switchNewCategoryButton(true, this.state.categoryName);
        } else {
            this.props.switchNewCategoryButton(false, this.state.categoryName);
        }
    };

    maker = opened => {
        const parents = this.state.parentCategories;
        const name = this.state.categoryName;
        if (opened) {
            if (parents.length === 0) {
                return (
                    <button
                        onClick={
                            this.hasSubCategories()
                                ? this.updateCategoryRendering
                                : this.props.type === 'selector'
                                    ? e => this.props.handleCatSelection(e, name)
                                    : this.updateCategoryRendering
                        }
                        className="TopCategory"
                    >
                        {this.state.categoryName}{' '}
                        {this.hasSubCategories() ? '▼' : null} &nbsp;
                    </button>
                );
            } else if (parents.length === 1) {
                return (
                    <button
                        onClick={
                            this.hasSubCategories()
                                ? this.updateCategoryRendering
                                : this.props.type === 'selector'
                                    ? e => this.props.handleCatSelection(e, name)
                                    : this.updateCategoryRendering
                        }
                        className="lvl2Category"
                    >
                        {this.state.categoryName}{' '}
                        {this.hasSubCategories() ? '▼' : null} &nbsp;
                    </button>
                );
            } else if (parents.length === 2) {
                return (
                    <button
                        onClick={
                            this.hasSubCategories()
                                ? this.updateCategoryRendering
                                : this.props.type === 'selector'
                                    ? e => this.props.handleCatSelection(e, name)
                                    : this.updateCategoryRendering
                        }
                        className="lvl3Category"
                    >
                        {this.state.categoryName}{' '}
                        {this.hasSubCategories() ? '▼' : null} &nbsp;
                    </button>
                );
            } else {
                return (
                    <button
                        onClick={
                            this.hasSubCategories()
                                ? this.updateCategoryRendering
                                : this.props.type === 'selector'
                                    ? e => this.props.handleCatSelection(e, name)
                                    : this.updateCategoryRendering
                        }
                        className="lvl4Category"
                    >
                        {this.state.categoryName}{' '}
                        {this.hasSubCategories() ? '▼' : null} &nbsp;
                    </button>
                );
            }
        } else if (parents.length === 0) {
            return (
                <button
                    onClick={
                        this.hasSubCategories()
                            ? this.updateCategoryRendering
                            : this.props.type === 'selector'
                                ? e => this.props.handleCatSelection(e, name)
                                : this.updateCategoryRendering
                    }
                    className="TopCategory"
                >
                    {' '}
                    {this.state.categoryName} &nbsp;{' '}
                </button>
            );
        } else if (parents.length === 1) {
            return (
                <button
                    onClick={
                        this.hasSubCategories()
                            ? this.updateCategoryRendering
                            : this.props.type === 'selector'
                                ? e => this.props.handleCatSelection(e, name)
                                : this.updateCategoryRendering
                    }
                    className="lvl2Category"
                >
                    {this.state.categoryName} &nbsp;
                </button>
            );
        } else if (parents.length === 2) {
            return (
                <button
                    onClick={
                        this.hasSubCategories()
                            ? this.updateCategoryRendering
                            : this.props.type === 'selector'
                                ? e => this.props.handleCatSelection(e, name)
                                : this.updateCategoryRendering
                    }
                    className="lvl3Category"
                >
                    {this.state.categoryName} &nbsp;
                </button>
            );
        } else {
            return (
                <button
                    className="lvl4Category"
                    onClick={
                        this.props.type === 'selector'
                            ? e => this.props.handleCatSelection(e, name)
                            : this.updateCategoryRendering
                    }
                >
                    {this.state.categoryName} &nbsp;
                </button>
            );
        }
    };

    makeChildren = () => {
        if (this.hasSubCategories) {
            const childrenToMake = this.getSubCategories();
            return childrenToMake.map(x => {
                return (
                    <TopCatManSort
                        categoryName={x.name}
                        key={x.name}
                        parentCategories={x.parents}
                        type={this.props.type}
                        switchNewCategoryButton={
                            this.props.switchNewCategoryButton
                        }
                        handleCatSelection={this.props.handleCatSelection}
                        tree={x.children}
                        updateSelection={this.props.updateSelection}
                    />
                );
            });
        } else {
            return null;
        }
    };

    render() {
        if (
            this.state.categoryName !== 'picture' &&
            this.state.categoryName !== 'Entries'
        ) {
            if (this.state.isOpened) {
                return (
                    <div className="Category-container">
                        {this.maker(true)}

                        {this.makeChildren()}
                    </div>
                );
            } else {
                return (
                    <div className="Category-container">
                        {this.maker(false)}
                    </div>
                );
            }
        } else {
            return null;
        }
    }
}
function getImage(category, categoryParents, tree) {
    let subTree = tree;
    // Get the image associated to a given category not yet done
    if (
        tree === undefined ||
        (!Object.prototype.hasOwnProperty.call(tree, category) &&
            !Object.prototype.hasOwnProperty.call(tree, categoryParents[0]))
    ) {
        return undefined;
    } else {
        for (let i = 0; i < categoryParents.length; i++) {
            subTree = subTree[categoryParents[i]];
        }
        // When a category has an invasive entry somewhere in it, the picture for it is an object instead of a string, so this:
        const x = subTree[category].picture;

        return typeof x === 'string' || x === undefined ? x : x.picture;
    }
}

class RightImage extends React.Component {
    constructor(props) {
        super(props);

        this.createEditButton = this.createEditButton.bind(this);
    }

    createEditButton = () => {
        return (
            <Link
                to={{
                    pathname: '/lsadmin/edit_category',
                    state: {
                        categoryName: this.props.mostRecentSelection,
                        categoryParents: this.props.choiceParents,
                    },
                }}
            >
                {this.props.type === 'selector' ? null : (
                    <button className="editCategory"> Edit Category </button>
                )}
            </Link>
        );
    };

    render() {
        return (
            <div>
                {this.props.mostRecentSelection === undefined ? null : (
                    <div className="selectedCategoryName">
                        {this.props.mostRecentSelection}
                    </div>
                )}
                {this.props.mostRecentSelection === undefined ? null : (
                    <div>
                        <img
                            className="rightImage"
                            src={`${imageurl}${this.props.image}`}
                        />
                        {this.props.mostRecentSelection === undefined
                            ? null
                            : this.createEditButton()}
                    </div>
                )}
            </div>
        );
    }
}

export { TopCatManSort, RightImage, getImage };
