import React from 'react';
import { Redirect } from 'react-router-dom';
import './TopBar.css';

class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loggedOut: false };
        this.handleSignout = this.handleSignout.bind(this);
    }

    handleSignout() {
        sessionStorage.clear();
        this.setState({ loggedOut: true });
    }

    render() {
        return (
            <div className="Top-bar">
                {this.state.loggedOut ? <Redirect to="/lsadmin" /> : null}
                <h1 className="Cite-title">
                    {`Lake Stewards of Maine - ${this.props.page}`}
                </h1>
                <button className="User-button" onClick={this.handleSignout}>
                    {this.props.page !== 'Registration'
                        ? 'Sign Out'
                        : 'Back To Login'}
                </button>
            </div>
        );
    }
}

export default TopBar;
