import React from 'react';
import { Link } from 'react-router-dom';
import './SideBar.css';
import { APIurl} from '../Router';

class SideBar extends React.Component {
    /*
     *Probably can be optimized better, but takes the name of the selected side bar
     *navigation as a prop and shades the selected button
     */
    render() {
        let NRBName = 'Side-button';
        let UABName = 'Side-button';
        let CCBName = 'Side-button';
        let MCBName = 'Side-button';
        let GLBName = 'Side-button';
        function NextReleaseButton(name) {
            return (
                <Link to="/lsadmin/changes">
                    <button className={name}>Changes</button>
                </Link>
            );
        }
        function UserAdminButton(name) {
            return (
                <Link to="/lsadmin/user_admin">
                    <button className={name}>User Administration</button>
                </Link>
            );
        }
        function CurrentCatalogButton(name) {
            return (
                <Link to="/lsadmin/catalog">
                    <button className={name}>Catalog</button>
                </Link>
            );
        }
        function ManageCategoriesButton(name) {
            return (
                <Link to="/lsadmin/manage_categories">
                    <button className={name}>Manage Categories</button>
                </Link>
            );
        }
	// add button to download the log
	function GetLog(name) {
		return (
			<Link to="/lsadmin/log">
				<button className={name}>Log</button>
			</Link>

		);
	}

        switch (this.props.selected) {
            case 'Next Release':
                NRBName = 'Selected-button';
                break;
            case 'User Admin':
                UABName = 'Selected-button';
                break;
            case 'Current Catalog':
                CCBName = 'Selected-button';
                break;
            case 'Manage Categories':
                MCBName = 'Selected-button';
                break;
	    case 'Download Log':
		GLBName = 'Selected-button';
		break;
            default:
        }

        return (
            <div className="Side-bar">
                {NextReleaseButton(NRBName)}
                {CurrentCatalogButton(CCBName)}
                {ManageCategoriesButton(MCBName)}
                {sessionStorage.getItem('permission') >= 2
                    ? UserAdminButton(UABName)
                    : null}
                {GetLog(GLBName)}
            </div>
        );
    }
}

export default SideBar;
