import React from 'react';
import { Link } from 'react-router-dom';
import SideBar from './Components/SideBar.js';
import './NewEntry.css';
import TopBar from './Components/TopBar.js';
import Popup from './Components/Popup.js';
import { APIurl } from './Router';

const async = require('async');

/*
 *This input only accepts images and allows you to upload them
 *<input type="file" accept="image/*"/>
 */

export class imageWithInfo {
    constructor(title, imageSource, credits, description) {
        this.title = title;
        this.imageSource = imageSource;
        this.credits = credits;
        this.description = description;
    }
}

const entryFields = [
    "Other\u0020Name(s)",
    "Domain",
    "Kingdom",
    "Phylum",
    "Class",
    "Order",
    "Family",
    "Details",
    "Identifying Characteristics",
    "Biology/Ecology",
    "Habitat",
    "Diet",
    "Look-Alikes",
    "Commonly Seen",
    "Range",
    "Subclass"
];

class EntryPage extends React.Component {
    render() {
        return (
            <div>
                <TopBar page="New Entry" />
                <SideBar />
                <Contents
                    pageType="New"
                    prevInfo={
                        this.props.location.state === undefined
                            ? undefined
                            : this.props.location.state.prevInfo
                    }
                    movedSpecies={
                        this.props.location.state === undefined
                            ? []
                            : this.props.location.state.movedSpecies
                    }
                    addedSpecies={
                        this.props.location.state !== undefined &&
                            this.props.location.state.addedSpecies !== []
                            ? this.props.location.state.addedSpecies
                            : []
                    }
                    members={
                        this.props.location.state !== undefined &&
                            this.props.location.state.members !== undefined
                            ? this.props.location.state.members
                            : []
                    }
                    new={
                        this.props.location.state === undefined
                            ? undefined
                            : this.props.location.state.new
                    }
                    parentCategories={
                        this.props.location.state === undefined
                            ? undefined
                            : this.props.location.state.prevInfo
                                .parentCategories
                    }
                />
            </div>
        );
    }
}

function nameAcceptable(stateName, propsName, speciesList) {
    // For entry names that must not be empty and must start with a capital letter
    if (stateName === '' || stateName === undefined || stateName === null) {
        return false;
    }
    const charList = stateName.split('');
    if (
        charList[0] === charList[0].toUpperCase() &&
        charList[0] !== charList[0].toLowerCase()
    ) {
        /*
         *Letter === uppercase version of itself
         *If this is editEntry page, then propsName will be the name that was previously attached to the entry.  If that hasn't changed it's fine.
         *If it has changed or this is newEntry page, check if stateName is already in the catalog to prevent duplicates.
         */
        if (stateName === propsName) {
            return true;
        } else {
            let toReturn = true;
            for (let i = 0; i < speciesList.length; i++) {
                if (speciesList[i].name === stateName) {
                    toReturn = false;
                }
            }
            return toReturn;
        }
    } else {
        return false;
    }
}

// Needs to know what info is filled out
class Contents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: this.props.images ? this.props.images : [],
            name: this.props.name,
            sciName: this.props.sciName,
            invasive: this.props.invasive ? true : false,
            category: this.props.prevInfo
                ? this.props.prevInfo.name
                : this.props.category,
            otherInfo:
                this.props.otherInfo !== undefined
                    ? this.props.otherInfo
                    : {
                        Family: undefined,
                        Classification: undefined,
                        Domain: undefined,
                        Kingdom: undefined,
                        Phylum: undefined,
                        Class: undefined,
                        Order: undefined,
                        Genus: undefined,
                        Species: undefined,
                        Subclass: undefined,
                        Range: undefined,
                        Details: undefined,
                        'Look-Alikes': undefined,
                        'Other Name(s)': undefined,
                        'Identifying Characteristics': undefined,
                        'Biology/Ecology': undefined,
                        Diet: undefined,
                        Habitat: undefined,
                        'Commonly Seen': undefined,
                    },
            speciesList: [],
            otherInfoHasChanged: false,
        };

        console.log(this.props.handleImagesChange);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSciNameChange = this.handleSciNameChange.bind(this);
        this.handleInvasiveChange = this.handleInvasiveChange.bind(this);
        this.handleImagesChange = this.handleImagesChange.bind(this);
        this.handleInfoChange = this.handleInfoChange.bind(this);
        this.makeInformation = this.makeInformation.bind(this);
        this.handleSubmission = this.handleSubmission.bind(this);
        this.formatImages = this.formatImages.bind(this);
        this.thereHaveBeenChanges = this.thereHaveBeenChanges.bind(this);
    }

    handleInfoChange(section, event, isTab) {
        const newOtherInfo = Object.assign({}, this.state.otherInfo);
        if (isTab === true) {
            // Getting cursor positon
            const cursorPos = event.target.selectionStart;
            // If a tab is entered in the middle of a line, don't allow it
            let prevChar;
            if (newOtherInfo[section]) {
                prevChar = newOtherInfo[section].charAt(cursorPos - 1);
            } else {
                return;
            }
            if (!prevChar || prevChar === '\t' || prevChar === '\n') {
                newOtherInfo[section] = `${newOtherInfo[section].substring(
                    0,
                    cursorPos
                )}\t${newOtherInfo[section].substring(cursorPos)}`;
            }
        } else {
            newOtherInfo[section] = event.target.value;
        }
        console.log(newOtherInfo);
        this.setState({ otherInfo: newOtherInfo, otherInfoHasChanged: true });
    }

    handleNameChange(e) {
        if(this.props.handleNameChange){
            this.props.handleNameChange(e);
        }
        else {
            this.setState({ name: e.target.value });
        }
    }

    handleSciNameChange(e) {
        //if(false && this.props.handleSciNameChange){
        //    this.props.handleSciNameChange(e);
        //}
        //else {
        this.setState({ sciName: e.target.value });
        //}
    }

    handleCategoryChange(e) {
        // if(this.props.handleCategoryChange){
        //     this.props.handleCategoryChange(e);
        // }
        // else {
            this.setState({ category: e.target.value });
        // }
    }

    handleInvasiveChange() {
        this.setState({ invasive: !this.state.invasive });
    }

    handleImagesChange(i) {
        console.log(i)
        this.setState({ images: i });
    }

    formatImages(imageArray) {
        const foo = imageArray.map(value => {
            return new imageWithInfo(
                value['name'] ? value['name'] : value['title'],
                value.image ? value.image : value.imageSource,
                value['credits'],
                value['description']
            );
        });
        return foo;
    }

    makeInformation() {
        const information = {};
        for (const key in this.state.otherInfo) {
            if (this.state.otherInfo[key] !== undefined) {
                information[key] = this.state.otherInfo[key];
            }
        }
        const parsedInfo = {};
        for (const subCat in information) {
            parsedInfo[subCat] = information[subCat].split('\n');
            if (parsedInfo[subCat][parsedInfo[subCat].length - 1] === '') {
                parsedInfo[subCat].pop();
            }
        }
        return parsedInfo;
    }

    // Before implementing, need to figure out how to handle storing a json file
    handleSubmission() {
        const parsedName = this.state.name
            .toLowerCase()
            .replace(/[ ]/g, '_')
            .replace(/[-']/g, '');
        const imageLocations = [];
        const images = this.state.images;
        const sciName = this.state.sciName;
        const name = this.state.name;
        const category = this.state.category
            ? this.state.category
            : 'other aquatic curiosities';
        const invasive = this.state.invasive;
        const information = this.makeInformation();

        fetch(`${APIurl}/species/next`, { method: 'get' })
            .then(response => response.json())
            .then(data => {
                const id = data.speciesId;

                async.forEachOf(
                    images,
                    function (image, key, callback) {
                        const form = new FormData();
                        if (!image.title) {
                            image.title = 'unknown';
                        }
                        if (!image.credits) {
                            image.credits = 'unknown';
                        }
                        if (!image.description) {
                            image.description = '';
                        }

                        form.append('name', image.title);
                        form.append('credits', image.credits);
                        form.append('description', image.description);
                        form.append('speciesId', id);
                        form.append('image', image.imageSource);

                        const options = {
                            method: 'post',
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: sessionStorage.getItem('token'),
                            },
                            body: form,
                        };

                        // This line allows the browser to automatically set the boundary between form rows
                        delete options.headers['Content-Type'];

                        fetch(`${APIurl}/catalog/add_image`, options)
                            .then(response => response.json())
                            .then(data => {
                                imageLocations.push(data.name);
                                callback();
                            })
                            .catch(err => callback(err));
                    },
                    function (err) {
                        if (err) {
                            console.log(`error: ${err}`);
                        } else {
                            const orderOptions = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: sessionStorage.getItem(
                                        'token'
                                    ),
                                },
                                body: JSON.stringify({
                                    imageArray: imageLocations,
                                }),
                            };

                            fetch(
                                `${APIurl}/catalog/order_images`,
                                orderOptions
                            )
                                .then(function (response) {
                                    return response.json();
                                })
                                .then(responseData => {
                                    const data = JSON.stringify({
                                        name: name,
                                        scientificName: sciName,
                                        category: category,
                                        invasive: invasive,
                                        jsonName: `${parsedName}.json`,
                                        information: information,
                                        pictures: imageLocations,
                                    });

                                    fetch(`${APIurl}/species/add`, {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: sessionStorage.getItem(
                                                'token'
                                            ),
                                        },
                                        body: data,
                                    }).then(response => {
                                        return response.json();
                                    });
                                })
                                .catch(err => console.log(err));
                        }
                    }
                );
            })
            .catch(err => console.log(err));
    }

    componentWillReceiveProps(nextProps) {
        const formattedImages = this.formatImages(nextProps.images);
        if (nextProps.name !== this.state.name) {
            this.setState({
                name: nextProps.name,
                images: formattedImages,
                sciName: nextProps.sciName,
                invasive: nextProps.invasive,
                otherInfo: nextProps.otherInfo,
                category: nextProps.category,
            });
        }
    }

    componentWillMount() {
        this.makeInformation();
    }

    componentDidMount() {
        fetch(`${APIurl}/species/all`, { method: 'GET' })
            .then(response => response.json())
            .then(response => {
                this.setState({ speciesList: response.species });
            });
    }

    thereHaveBeenChanges(otherInfosHaveChanged) {
        const propImages = this.props.images ? this.props.images : [];
        const stateImages = this.state.images;
        let imagesAreSame = false;

        if (propImages.length === stateImages.length) {
            imagesAreSame = true;
            for (let i = 0; i < propImages.length; i++) {
                if (
                    propImages[i].credits !== stateImages[i].credits ||
                    propImages[i].description !== stateImages[i].description ||
                    propImages[i].name !== stateImages[i].title ||
                    propImages[i].image !== stateImages[i].imageSource
                ) {
                    imagesAreSame = false;
                }
            }
        }

        return !(
            this.props.name === this.state.name &&
            this.props.category === this.state.category &&
            this.props.sciName === this.state.sciName &&
            this.props.invasive === this.state.invasive &&
            !otherInfosHaveChanged &&
            imagesAreSame
        );
    }

    render() {
        //console.log(this.state.images)
        return (
            <div id="Contents">
                <PicturesPanel
                    highlightedEntries={this.props.highlightedEntries}
                    highlighted={this.props.highlighted}
                    handleImagesChange={
                        this.props.handleImagesChange
                            ? this.props.handleImagesChange
                            : this.handleImagesChange
                    }
                    pageType={this.props.pageType}
                    images={
                        this.state.images === undefined ? [] : this.state.images
                    }
                />
                <HighlightedKey/>
                <InputsPanel
                    highlighted={this.props.highlighted}
                    newSpecies={this.props.newSpecies}
                    highlightedEntries={this.props.highlightedEntries}
                    toggleHighlighted={this.props.toggleHighlighted}
                    handleNameChange={this.handleNameChange}
                    handleSciNameChange={this.handleSciNameChange}
                    handleCategoryChange={this.handleCategoryChange}
                    handleInvasiveChange={this.handleInvasiveChange}
                    handleInfoChange={this.handleInfoChange}
                    information={this.state}
                    gotCategory={this.props.prevInfo !== undefined}
                    pageType={this.props.pageType}
                    fromCategory={
                        this.props.prevInfo
                            ? this.props.prevInfo.name
                            : undefined
                    }
                    category={this.state.category}
                />
                <ButtonsPanel
                    handleSubmission={this.handleSubmission}
                    handleEditSubmission={() =>
                        this.props.handleEditSubmission(this.state)
                    }
                    pageType={this.props.pageType}
                    name={this.state.name}
                    new={this.props.new}
                    speciesInfo={this.state}
                    prevInfo={this.props.prevInfo}
                    members={this.props.members}
                    categoryName={
                        this.props.prevInfo
                            ? this.props.prevInfo.name
                            : undefined
                    }
                    movedSpecies={this.props.movedSpecies}
                    addedSpecies={this.props.addedSpecies}
                    parentCategories={this.props.parentCategories}
                    /* First arg is changed name, second arg is either undefined or the initial name */
                    canSubmit={
                        this.thereHaveBeenChanges(
                            this.state.otherInfoHasChanged
                        ) &&
                        nameAcceptable(
                            this.state.name,
                            this.props.name,
                            this.state.speciesList
                        ) &&
                        /*
                         * This.state.sciName !== '' &&
                         * this.state.sciName !== undefined &&
                         */
                        this.state.category !== '' &&
                        this.state.images.length > 0
                    }
                />
            </div>
        );
    }
}

class HighlightedKey extends React.Component {
    render(){
        return(
            <div class='highlightKey'>
                <div class="keyLayout"><div class='box red'></div><div>: Removed Images</div></div>
                <br></br>
                <div class="keyLayout"><div class='box green'></div><div>: Added Images</div></div>
                <br></br>
                <div class="keyLayout"><div class='box yellow'></div><div>: Primary Image Change</div></div>
            </div>
        );
    }
}

// Needs to know and tell pictures

// Handles the pictures related to the currently targeted species
class PicturesPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupActive: false,
            popupState: undefined,
            popupIndex: undefined,
            images: this.props.images,
        };
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleRightArrowClick = this.handleRightArrowClick.bind(this);
        this.handleLeftArrowClick = this.handleLeftArrowClick.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);
    }

    // If the photos given to this component are updated, make sure state changes
    componentWillReceiveProps(nextProps) {
        this.setState({ images: nextProps.images });
    }

    // Cancel a change to an image
    handleCancelClick() {
        this.setState({ popupActive: false, popupState: undefined });
    }

    // Completes the submission of a change to a photo
    handleSubmitClick(title, image, credits, description) {
        // Should upload form to database
        const temp = this.state.images;
        if (this.state.popupState === undefined) {
            temp.push(new imageWithInfo(title, image, credits, description));
            this.setState({ images: temp, popupState: undefined });
            this.props.handleImagesChange(this.state.images);
        } else {
            temp[this.state.popupIndex] = new imageWithInfo(
                title,
                image,
                credits,
                description
            );
            this.setState({ images: temp, popupState: undefined });
            this.props.handleImagesChange(this.state.images);
        }
    }

    handleDeleteClick(e) {
        const newImages = this.state.images;
        newImages.splice(e.target.dataset.index, 1);
        this.setState({
            images: newImages,
        });
        this.props.handleImagesChange(this.state.images);
    }

    handleLeftArrowClick(e) {
        const tempArray = this.state.images;
        const index = parseInt(e.target.dataset.index);
        const a = tempArray[index - 1];
        tempArray[index - 1] = tempArray[index];
        tempArray[index] = a;
        this.setState({ images: tempArray });
        this.props.handleImagesChange(this.state.images);
    }

    handleRightArrowClick(e) {
        const tempArray = this.state.images;
        const index = parseInt(e.target.dataset.index);
        const a = tempArray[index + 1];
        tempArray[index + 1] = tempArray[index];
        tempArray[index] = a;
        this.setState({ images: tempArray });
        this.props.handleImagesChange(this.state.images);
    }

    handleImageClick(e) {
        const index = e.target.dataset.index;
        this.setState({
            popupState: this.state.images[index],
            popupIndex: index,
            popupActive: true,
        });
        //console.log(this.highlighted);
    }

    // Iterates through all the image data and generates a picture frame for each photo
    generatePictures() {
        var imageSet;
        if(this.props.highlighted && this.props.highlightedEntries !== undefined){
            if(this.props.highlightedEntries["images"] !== undefined){
                imageSet = this.props.highlightedEntries['images'];
            }else{
                imageSet = this.state.images;
            }
        }else{
            imageSet = this.state.images;
        }
        if (imageSet.length > 0) {
            const lastIndex = imageSet.length - 1;
            const foo = imageSet.map((item, i) => {
                return (
                    <PictureFrame
                        // highlighted={this.props.highlighted && 
                        //     this.props.highlightedEntries && 
                        //     this.props.highlightedEntries.includes(item.imageSource)}
                        pageType={this.props.pageType}
                        source={item}
                        key={i}
                        index={i}
                        isLast={i === lastIndex}
                        handleDeleteClick={this.handleDeleteClick}
                        handleLeftArrowClick={this.handleLeftArrowClick}
                        handleRightArrowClick={this.handleRightArrowClick}
                        handleImageClick={this.handleImageClick}
                        highlighted={this.props.highlighted}
                        highlightedEntries={this.props.highlightedEntries}
                    />
                );
            });
            return foo;
        } else {
            return null;
        }
    }

    render() {
        return (
            <div id="PicturesPanel">
                {
                    this.props.highlighted ?
                console.log(this.props.highlightedEntries)
                : ""
            }
                {this.state.popupActive ? (
                    <Popup
                        contents={this.props.highlighted && this.props.highlightedEntries["images"] !== undefined ? this.props.highlightedEntries["images"][this.state.popupIndex] : this.state.popupState}
                        isAddImage="true"
                        pageType={this.props.pageType}
                        highlighted={this.props.highlighted}
                        handleCancelClick={this.handleCancelClick}
                        handleSubmitClick={this.handleSubmitClick}
                    />
                ) : null}
                {this.generatePictures()}
                {this.props.pageType !== 'Review' ? (
                    <div className="Picture-frame">
                        {this.state.images.length === 0 ? (
                            <p id="Primary-pic-label">Primary Image*</p>
                        ) : (
                            <p id="Primary-pic-label">Additional Image</p>
                        )}
                        <button
                            className="Add-button"
                            onClick={() => {
                                this.setState({ popupActive: true });
                            }}
                        >
                            +
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }
}
function urlReturner(weirdObject, highlighted, highlightedEntries) {
    console.log(weirdObject)
    var imagePath;
    if(highlighted && highlightedEntries){
        imagePath = 'image';
    }else{
        imagePath = 'imageSource';
    }
    return URL.createObjectURL(weirdObject[imagePath]);
}
// Needs to know and tell what pictures we have

export class PictureFrame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: this.props.index,
        };
    }

    render() {
        if (this.props.singleImage === true) {
            return (
                <div className="Picture-frame" data-index={this.state.index}>
                    {this.props.invasiveImage ? (
                        <p id="Primary-pic-label">Invasive Image</p>
                    ) : (
                        <p id="Primary-pic-label">Primary Image*</p>
                    )}
                    <div className="Image-section">
                        <img
                            className="Image"
                            src={
                                typeof this.props.source === 'string'
                                    ? this.props.source
                                    : urlReturner(this.props.source)
                            }
                            alt="Foooood"
                            onClick={this.props.handleImageClick}
                            data-index={this.state.index}
                        />
                        <button
                            className="Delete"
                            onClick={this.props.handleDeleteClick}
                            data-index={this.state.index}
                        >
                            X
                        </button>
                        {this.props.source.title === 'unknown' ||
                        this.props.source.title === null ||
                        this.props.source.title === 'null' ? (
                                ''
                            ) : (
                                <p className="Picture-title">
                                    {this.props.source.title}
                                </p>
                            )}
                        <p className="Picture-credits">
                            {this.props.source['credits'] === undefined ||
                            this.props.source.credits === 'unknown' ||
                            this.props.source.credits === null ||
                            this.props.source.credits === 'null'
                                ? ''
                                : `Photo by ${this.props.source['credits']}`}
                        </p>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Picture-frame" data-index={this.state.index}>
                    {this.props.invasiveImage ? (
                        <p id="Primary-pic-label">Invasive Image</p>
                    ) : this.state.index === 0 ? (
                        <p id="Primary-pic-label">Primary Image*</p>
                    ) : (
                        <p id="Primary-pic-label">Additional Image</p>
                    )}

                    <div className={this.props.highlighted && this.props.highlightedEntries !== undefined ?
                                    this.props.source.added ? "Image-section Image-sectionAdded" : 
                                    this.props.source.removed ? "Image-section Image-sectionRemove" :
                                    this.props.source.changed ? "Image-section Image-sectionChange" :
                                    "Image-section" : "Image-section"}>
                        <img
                            className="Image"
                            src={this.props.highlighted && this.props.highlightedEntries !== undefined ? (
                                this.props.highlightedEntries["images"] !== undefined ?(
                                typeof this.props.source['image']['image'] ===
                                'string'
                                    ? this.props.source['image']['image']
                                    : URL.createObjectURL(
                                        this.props.source['image']['image']
                                    )):
                                typeof this.props.source['imageSource'] ===
                                'string'
                                    ? this.props.source['imageSource']
                                    : URL.createObjectURL(
                                        this.props.source['imageSource']
                                    )):(
                                typeof this.props.source['imageSource'] ===
                                'string'
                                    ? this.props.source['imageSource']
                                    : URL.createObjectURL(
                                        this.props.source['imageSource']
                                    ))
                            } /* This.props.source["imageSource"] */
                            onClick={this.props.handleImageClick}
                            alt="Fodo"
                            data-index={this.state.index}
                        />
                        {this.props.pageType !== 'Review' ? (
                            <button
                                className="Delete"
                                onClick={this.props.handleDeleteClick}
                                data-index={this.state.index}
                            >
                                X
                            </button>
                        ) : null}
                        {this.state.index !== 0 &&
                        this.props.pageType !== 'Review' ? (
                                <button
                                    id="LeftArrow"
                                    onClick={this.props.handleLeftArrowClick}
                                    data-index={this.state.index}
                                >
                                    {'<'}
                                </button>
                            ) : null}
                        {this.props.isLast ||
                        this.props.pageType === 'Review' ? null : (
                                <button
                                    id="RightArrow"
                                    onClick={this.props.handleRightArrowClick}
                                    data-index={this.state.index}
                                >
                            
                                </button>
                            )}
                        <p className="Picture-title">
                            {/* {console.log(this.props.source)} */}
                            {this.props.source.title === undefined ||
                            this.props.source.title === 'unknown' ||
                            this.props.source.title === null ||
                            this.props.source.title === 'null' ? (
                                this.props.source.image !== undefined ?
                                    this.props.source.image.name === undefined ||
                                    this.props.source.image.name === 'unknown' ||
                                    this.props.source.image.name === null ||
                                    this.props.source.image.name === 'null' ? (
                                    ''
                                ) :
                                    typeof(this.props.source.image.name) !== 'object' ?
                                        this.props.source.image.name
                                 : 
                                    this.props.source.image.name !== undefined && typeof(this.props.source.image.name) === 'object' ?
                                    this.props.source.image.name.map(change => {
                                        return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                                    }) : "" : ""
                                ): this.props.source.title
                            }
                        </p>
                            //{console.log("RIGHT HERE")}
                        <p className="Picture-credits">
                            {this.props.source.credits === undefined ||
                            this.props.source.credits === 'unknown' ||
                            this.props.source.credits === null ||
                            this.props.source.credits === 'null'? 
                                (this.props.source.image !== undefined) ?
                                    this.props.source.image.credits === undefined ||
                                    this.props.source.image.credits === 'unknown' ||
                                    this.props.source.image.credits === null ||
                                    this.props.source.image.credits === 'null' ? ""
                                    : typeof(this.props.source.image.credits) !== 'object' ?
                                    `Photo by ${this.props.source.image.credits}`
                                : this.props.source.image.credits !== undefined && typeof(this.props.source.image.credits) === 'object' ?
                                    this.props.source.image.credits.map(change => {
                                        return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                                    }) : "" : ""
                                : `Photo by ${this.props.source['credits']}`}
                        </p>
                    </div>
                </div>
            );
        }
    }
}

// export class PictureFrame extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             index: this.props.index,
//             highlighted: this.props.highlighted
//         };
//     }

//     render() {
//         const highlighted = this.props.highlighted ? " Highlighted" : ""
//         if (this.props.singleImage === true) {
//             return (
//                 <div className={"Picture-frame"} data-index={this.state.index}>
//                     {this.props.invasiveImage ? (
//                         <p id="Primary-pic-label">Invasive Image</p>
//                     ) : (
//                         <p id="Primary-pic-label">Primary Image*</p>
//                     )}
//                     <div className="Image-section">
//                         <img
//                             className="Image"
//                             src={
//                                 typeof this.props.source === 'string'
//                                     ? this.props.source
//                                     : urlReturner(this.props.source)
//                             }
//                             alt="Image Failed to Render"
//                             onClick={this.props.handleImageClick}
//                             data-index={this.state.index}
//                         />
//                         <button
//                             className="Delete"
//                             onClick={this.props.handleDeleteClick}
//                             data-index={this.state.index}
//                         >
//                             X
//                         </button>
//                         {this.props.source.title === 'unknown' ||
//                             this.props.source.title === null ||
//                             this.props.source.title === 'null' ? (
//                             ''
//                         ) : (
//                             <p className="Picture-title">
//                                 {this.props.source.title}
//                             </p>
//                         )}
//                         <p className="Picture-credits">
//                             {this.props.source['credits'] === undefined ||
//                                 this.props.source.credits === 'unknown' ||
//                                 this.props.source.credits === null ||
//                                 this.props.source.credits === 'null'
//                                 ? ''
//                                 : `Photo by ${this.props.source['credits']}`}
//                         </p>
//                     </div>
//                 </div>
//             );
//         } else {
//             //console.log(this.props.highlightedEntries, this.props.highlighted)
//             //console.log(this.props.source)
//             return (
//                 <div className={"Picture-frame"} data-index={this.state.index}>
//                     {this.props.invasiveImage ? (
//                         <p id="Primary-pic-label">Invasive Image</p>
//                     ) : this.state.index === 0 ? (
//                         <p id="Primary-pic-label">Primary Image*</p>
//                     ) : (
//                         <p id="Primary-pic-label">Additional Image</p>
//                     )}

//                     <div className={this.props.highlighted && this.props.highlightedEntries !== undefined ?
//                                     this.props.source.added ? "Image-section Image-sectionAdded" : 
//                                     this.props.source.removed ? "Image-section Image-sectionRemove" :
//                                     this.props.source.changed ? "Image-section Image-sectionChange" :
//                                     "Image-section" : "Image-section"}>
//                         <img
//                             className="Image"
//                             src={this.props.highlighted && this.props.highlightedEntries !== undefined ? (
//                                 this.props.highlightedEntries["images"] !== undefined ?(
//                                 typeof this.props.source['image']['image'] ===
//                                 'string'
//                                     ? this.props.source['image']['image']
//                                     : URL.createObjectURL(
//                                         this.props.source['image']['image']
//                                     )):
//                                 typeof this.props.source['imageSource'] ===
//                                 'string'
//                                     ? this.props.source['imageSource']
//                                     : URL.createObjectURL(
//                                         this.props.source['imageSource']
//                                     )):(
//                                 typeof this.props.source['imageSource'] ===
//                                     'string'
//                                     ? this.props.source['imageSource']
//                                     : URL.createObjectURL(
//                                         this.props.source['imageSource']
//                                     ))
//                             } /* This.props.source["imageSource"] */
//                             onClick={this.props.handleImageClick}
//                             alt="Fodo"
//                             data-index={this.state.index}
//                         />
//                         {this.props.pageType !== 'Review' ? (
//                             <button
//                                 className="Delete"
//                                 onClick={this.props.handleDeleteClick}
//                                 data-index={this.state.index}
//                             >
//                                 X
//                             </button>
//                         ) : null}
//                         {this.state.index !== 0 &&
//                             this.props.pageType !== 'Review' ? (
//                             <button
//                                 id="LeftArrow"
//                                 onClick={this.props.handleLeftArrowClick}
//                                 data-index={this.state.index}
//                             >
//                                 {'<'}
//                             </button>
//                         ) : null}
//                         {this.props.isLast ||
//                             this.props.pageType === 'Review' ? null : (
//                             <button
//                                 id="RightArrow"
//                                 onClick={this.props.handleRightArrowClick}
//                                 data-index={this.state.index}
//                             >
//                                 {'>'}
//                             </button>
//                         )}
//                         {this.props.source.title === 'unknown' ||
//                             this.props.source.title === null ||
//                             this.props.source.title === 'null' ? (
//                             ''
//                         ) : (
//                             <p className="Picture-title">
//                                 {this.props.source.title}
//                             </p>
//                         )}
//                         <p className="Picture-credits">
//                             {this.props.source['credits'] === undefined ||
//                                 this.props.source.credits === 'unknown' ||
//                                 this.props.source.credits === null ||
//                                 this.props.source.credits === 'null'
//                                 ? ''
//                                 : `Photo by ${this.props.source['credits']}`}
//                         </p>
//                     </div>
//                 </div>
//             );
//         }
//     }
// }

// Needs to tell what info is filled out

function getArrayOfBottomCategories(tree) {
    function isBottomCategory(subTree) {
        // If the only things a category has in it are its invasive and uninvasive images and species which are remembered elsewhere, then it is a bottom level category
        return Object.keys(subTree).length <= 2;
    }
    const toReturn = [];
    function helper(subTree) {
        for (let i = 0; i < Object.keys(subTree).length; i++) {
            const ithKey = Object.keys(subTree)[i];
            if (ithKey !== 'picture' && ithKey !== 'invasivePicture') {
                if (isBottomCategory(subTree[ithKey])) {
                    toReturn.push(ithKey);
                } else {
                    helper(subTree[ithKey]);
                }
            }
        }
    }
    helper(tree);
    return toReturn;
}
class InputsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            bottomLevelCategories: [],
        };
    }

    componentDidMount() {
        if (this.props.fromCategory) {
            this.setState({
                bottomLevelCategories: this.state.bottomLevelCategories.concat([
                    this.props.fromCategory,
                ]),
            });
        }

        const URL = `${APIurl}/tree/all`;
        fetch(URL, {
            method: 'GET',
            headers: new Headers({
                Authorization: sessionStorage.getItem('token'),
            }),
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    bottomLevelCategories: getArrayOfBottomCategories(
                        response.tree
                    ),
                });
            });
    }

    render() {
        const isReview = this.props.pageType === 'Review';
        return (
            <div className="Species-form">
                {this.props.highlighted !== undefined ? (
                    <div className="Full-div">
                        <label>Highlight Changed Fields: </label>
                        <input
                            type="checkbox"
                            onChange={() =>
                                this.props.toggleHighlighted(
                                    this.props.information.name
                                )
                            }
                        />
                    </div>
                ) : null}
                <div className="Entry-div">
                    <label
                        className={
                            this.props.highlighted &&
                                (this.props.newSpecies ||
                                    (this.props.highlightedEntries &&
                                        this.props.highlightedEntries['Name']))
                                ? 'Highlighted'
                                : ''
                        }
                    >
                        Name (Capitalized)*:{' '}
                    </label>
                    {isReview ? <p className="review_text_line">{
                        this.props.information.name && this.props.highlighted && this.props.highlightedEntries ?
                        this.props.highlightedEntries['Name'] !== undefined ? 
                            this.props.highlightedEntries['Name'].map(change => {
                                //console.log(this.props.highlightedEntries);
                                return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                            }):this.props.information.name : this.props.information.name
                    }</p>:
                    <input
                        readOnly={isReview}
                        type="text"
                        className="Small-input"
                        onChange={this.props.handleNameChange}
                        value={this.props.information.name}
                    />}
                </div>
                <div className="Entry-div">
                    <label
                        className={
                            this.props.highlighted &&
                                (this.props.newSpecies ||
                                    (this.props.highlightedEntries &&
                                        this.props.highlightedEntries[
                                            'Scientific Name'
                                        ]))
                                ? 'Highlighted'
                                : ''
                        }
                    >
                        Scientific Name:{' '}
                    </label>
                    {
                        isReview ? <p className="review_text_line">{
                            this.props.highlighted && this.props.highlightedEntries ?
                                                        this.props.highlightedEntries['Scientific Name'] !== undefined ? 
                                                            this.props.highlightedEntries['Scientific Name'].map(change => {
                                                                return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                                                            }):this.props.information.sciName : this.props.information.sciName}</p>:
                        <input
                            readOnly={isReview}
                            type="text"
                            className="Small-input"
                            onChange={this.props.handleSciNameChange}
                            value={this.props.information.sciName}
                        />
                    }
                    
                </div>
                <div className="Entry-div">
                    <label
                        className={
                            this.props.highlighted &&
                                ((this.props.newSpecies &&
                                    this.props.information.invasive) ||
                                    (this.props.highlightedEntries &&
                                        this.props.highlightedEntries[
                                            'Invasive'
                                        ]))
                                ? 'Highlighted'
                                : ''
                        }
                    >
                        Invasive:{' '}
                    </label>
                    {isReview ? (
                        this.props.information.invasive ? (
                            <input type="checkbox" checked disabled />
                        ) : (
                            <input type="checkbox" disabled />
                        )
                    ) : this.props.information.invasive ? (
                        <input
                            readOnly={isReview}
                            type="checkbox"
                            onChange={this.props.handleInvasiveChange}
                            checked
                        />
                    ) : (
                        <input
                            readOnly={isReview}
                            type="checkbox"
                            onChange={this.props.handleInvasiveChange}
                        />
                    )}
                </div>

                <div className="Entry-div">
                    <label
                        className={
                            this.props.highlighted &&
                                (this.props.newSpecies ||
                                    (this.props.highlightedEntries &&
                                        this.props.highlightedEntries[
                                            'Category'
                                        ]))
                                ? 'Highlighted'
                                : ''
                        }
                    >
                        Category*:{' '}
                    </label>
                    {this.props.pageType === 'Review' ? (
                        <p className="review_text_line">{
                            this.props.category && this.props.highlighted && this.props.highlightedEntries ?
                                                        this.props.highlightedEntries['Category'] !== undefined ? 
                                                            this.props.highlightedEntries['Category'].map(change => {
                                                                //console.log(change);
                                                                return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                                                            }):this.props.category : this.props.category}</p>
                    ) : this.props.fromCategory === undefined ? (
                        <select
                            onChange={this.props.handleCategoryChange}
                            value={
                                this.props.information.category
                                    ? this.props.information.category
                                    : 'other aquatic curiosities'
                            }
                        >
                            {this.state.bottomLevelCategories.map((item, i) => {
                                return (
                                    <option key={i} value={item}>
                                        {item}
                                    </option>
                                );
                            })}
                        </select>
                    ) : (
                        this.props.fromCategory
                    )}
                </div>

                {/*
                Below this point, all the info items are handled-----------------------
                */
                    entryFields.map(field => {
                            return(
                                <div className="Field-div">
                                    <label>
                                        {field}:{' '} 
                                    </label>
                                    {isReview ?
                                        <div className="review_text_box">{
                                            // Populates the text boxes for each field. 
                                            // If the highlighted box is checked it will loop through the array of changes for a given field. 
                                            // Each index of the change array will be highlighted or left alone as needed.
                                                this.props.information.otherInfo[field] && this.props.highlighted && this.props.highlightedEntries ?
                                                        this.props.highlightedEntries[field] !== undefined ? 
                                                            this.props.highlightedEntries[field].map(change => {
                                                                return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                                                            }):this.props.information.otherInfo[field] : this.props.information.otherInfo[field]
                                                    }</div>
                                            
                                        :
                                        <textarea
                                        readOnly={isReview}
                                        className="Large-input"
                                        value={ this.props.highlighted && this.props.highlightedEntries?
                                            this.props.information.otherInfo[field] +
                                            this.props.highlightedEntries[field]:
                                            this.props.information.otherInfo[field]
                                        } 
                                        onKeyDown={e => {
                                            if(this.props.highlighted)
                                                //console.log(this.props.highlightedEntries[field]);
                                                //console.log(this.props.highlightedEntries);
                                            if ((e.keyCode || e.which) == 9) {
                                                e.preventDefault();
                                                this.props.handleInfoChange(
                                                    field,
                                                    e,
                                                    true
                                                );
                                            }
                                        }}
                                        onChange={e =>
                                            this.props.handleInfoChange(field, e)
                                        }
                                    /> 
                                    }
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

/*function myPush(array, ele) {
    array.push(ele);
    return array;
}*/

function review(name) {
    fetch(`${APIurl}/species/review`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ name: name }),
    });
}

// Needs to know if enough information is filled out
function ButtonsPanel(props) {
    switch (props.pageType) {
        case 'New': {
            return (
                <span>
                    {props.canSubmit ? (
                        props.prevInfo === undefined ? (
                            <Link
                                onClick={props.handleSubmission}
                                to={'/lsadmin/changes'}
                            >
                                {' '}
                                <button className="Submit-button">
                                    Submit
                            </button>{' '}
                            </Link>
                        ) : (
                            <Link
                                to={
                                    props.new
                                        ? {
                                            pathname:
                                                '/new_category' /* Props.new ? "/new_category" : "/edit_category", */,
                                            state: {
                                                addedSpecies:
                                                    props.addedSpecies,
                                                newInfo: props.speciesInfo,
                                                movedSpecies:
                                                    props.movedSpecies,
                                                members: props.members,
                                                newGuyName: props.name,
                                                prevInfo: props.prevInfo,
                                                categoryParents: [],
                                            },
                                        }
                                        : {
                                            pathname:
                                                '/edit_category' /* Props.new ? "/new_category" : "/edit_category", */,
                                            state: {
                                                categoryName:
                                                    props.categoryName,
                                                categoryParents:
                                                    props.parentCategories,
                                                addedSpecies:
                                                    props.addedSpecies,
                                                newInfo: props.speciesInfo,
                                                movedSpecies:
                                                    props.movedSpecies,
                                                members: props.members,
                                                newGuyName: props.name,
                                                prevInfo: props.prevInfo,
                                            },
                                        }
                                }
                            >
                                <button className="Submit-button">
                                    Submit
                            </button>
                            </Link>
                        )
                    ) : null}
                    <button
                        className="Cancel-button"
                        onClick={() => {
                            // eslint-disable-next-line
                            history.back();
                        }}
                    >
                        Cancel
                </button>
                </span>
            );
        }

        case 'Edit': {
            return (
                <span>
                    {props.canSubmit ? (
                        <Link
                            to="/lsadmin/changes"
                            onClick={props.handleEditSubmission}
                        >
                            <button className="Submit-button">Submit</button>
                        </Link>
                    ) : null}
                    <Link to="/lsadmin/catalog">
                        <button
                            className="Cancel-button"
                        >
                            Cancel
                    </button>
                    </Link>
                </span>
            );
        }

        case 'Review': {
            return (
                <span>
                    <Link to="/lsadmin/changes">
                        {' '}
                        <button
                            onClick={() => review(props.name)}
                            className="Submit-button"
                        >
                            Mark As Reviewed
                    </button>{' '}
                    </Link>
                    <Link
                        to={{
                            pathname: '/lsadmin/edit_entry',
                            state: { name: props.name },
                        }}
                    >
                        <button className="Revise-button">
                            Revise Changes
                    </button>
                    </Link>
                    <button
                        className="Cancel-button"
                        onClick={() => {
                            // eslint-disable-next-line
                            history.back();
                        }}
                    >
                        Cancel
                </button>
                </span>
            );
        }

        default: {
            return (
                <button
                    className="Cancel-button"
                    onClick={() => {
                        // eslint-disable-next-line
                        history.back();
                    }}
                >
                    Cancel
                </button>
            );
        }
    }
}

export default EntryPage;
export { Contents };
