import React from 'react';
import './PicturePopup.css';

/*
 *This variable runs a number of checks to detect whether dragging and dropping images is supported
 *by the browser; is true if it is, false if not.
 *Need to handle case where this is false better
 */
let isAdvancedUpload = (function() {
    let div = document.createElement('div');
    return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
    );
})();

class PicturePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image:
                this.props.contents === undefined
                    ? null
                    : this.props.highlighted && this.props.contents.image !== undefined ?
                    this.props.contents.image.image :
                    this.props.contents.imageSource,
            title:
                this.props.contents === undefined
                    ? null
                    : this.props.highlighted && this.props.contents.image !== undefined ?
                    this.props.contents.image.name :
                    this.props.contents.title,
            credits:
                this.props.contents === undefined
                    ? null
                    : this.props.highlighted && this.props.contents.image !== undefined ?
                    this.props.contents.image.credits :
                    this.props.contents.credits,
            description:
                this.props.contents === undefined
                    ? null
                    : this.props.highlighted && this.props.contents.image !== undefined ?
                    this.props.contents.image.description :
                    this.props.contents.description,
            imageUrl: undefined,
        };
        console.log(this.state)
        this.highlighted = this.props.highlighted;
        this.pageType = this.props.pageType;
        this.pickImage = this.pickImage.bind(this);
        this.submit = this.submit.bind(this);
        this.titleChange = this.titleChange.bind(this);
        this.creditChange = this.creditChange.bind(this);
        this.descriptionChange = this.descriptionChange.bind(this);
    }

    pickImage(im) {
        this.setState({ image: im });
        this.setState({ imageUrl: im instanceof URL ? im : URL.createObjectURL(im) });
    }

    // Wrote seperate function so clicking submit both adds the image to the form and closes the popup
    submit() {
        this.props.handleSubmitClick(
            this.state.title,
            this.state.image,
            this.state.credits,
            this.state.description
        );
        this.props.handleCancelClick();
    }

    titleChange(event) {
        this.setState({ title: event.target.value });
    }

    creditChange(event) {
        this.setState({ credits: event.target.value });
    }

    descriptionChange(event) {
        this.setState({ description: event.target.value });
    }

    // Also, need to clean up interface for when dragging isn't allowed - it should at least display the image

    render() {
        if (!isAdvancedUpload) {
            alert('drag and drop not supported');
        }
        return (
            <div className="Inner-popup">
                {isAdvancedUpload ? (
                    <PictureDropper
                        pickImage={this.pickImage}
                        image={this.state.image}
                        imageUrl={this.state.imageUrl}
                    />
                ) : (
                    <input
                        type="file"
                        accept="image/*"
                        className="File-searcher"
                    ></input>
                )}
                <label className="Popup-label">Title: </label>
                {this.pageType !== "Review" ? (
                <input
                    onChange={this.titleChange}
                    type="text"
                    className="Long-input"
                    defaultValue={this.state.title}
                ></input>) : (
                    this.highlighted && this.props.contents.image !== undefined ? 
                    <p className="Long-input-review">{
                        this.state.title.map(change => {
                            return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                        })
                    }</p> :
                    <p className="Long-input-review">{this.state.title}</p>
                )}
                <label className="Popup-label">Image Credit: </label>
                {this.pageType !== "Review" ? (
                    <input
                    onChange={this.creditChange}
                    type="text"
                    className="Long-input"
                    defaultValue={this.state.credits}
                ></input>
                ) : (
                    this.highlighted && this.props.contents.image !== undefined ? 
                    <p className="Long-input-review">{
                        this.state.credits.map(change => {
                            return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                        })
                    }</p> :
                    <p class="Long-input-review">{this.state.credits}</p>
                )}
                
                <label className="Popup-label">Description: </label>
                {this.pageType !== "Review" ? (
                    <textarea
                    className="Large-area"
                    onChange={this.descriptionChange}
                    defaultValue={this.state.description}
                ></textarea>
                ) : (
                    this.highlighted && this.props.contents.image !== undefined ? 
                    <p className="Long-input-review">{
                        this.state.description.map(change => {
                            return((change.added==true)?<span className="Added">{change.value}</span>:(change.removed==true)?<span className="Removed">{change.value}</span>:change.value)
                        })
                    }</p> :
                    <p class="Long-input-review">{this.state.description}</p>
                )}
                
                <button
                    id="Cancel-button"
                    onClick={this.props.handleCancelClick}
                >
                    Cancel
                </button>
                {this.state.image !== null &&
                this.pageType !== "Review" ? (
                    <button id="Submit-button" onClick={this.submit}>
                        Submit
                    </button>
                ) : null}
            </div>
        );
    }
}

class PictureDropper extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            dragging: false,
            image: this.props.image,
            imageUrl: this.props.imageUrl,
        };
        this.handleDragIn = this.handleDragIn.bind(this);
        this.handleDragOut = this.handleDragOut.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleNonDropFileInput = this.handleNonDropFileInput.bind(this);
    }

    handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();

        /*
         *Checks that only one file is uploaded (not 0 or multiple) - gets caught when trying to
         *drop an image directly from google
         */
        if (e.dataTransfer.files.length !== 1) {
            alert(
                'Sorry - file input not accepted. Please try an image from your computer.'
            );

            // Checks that any uploaded file is an image
        } else if (e.dataTransfer.files[0].type.substring(0, 5) !== 'image') {
            alert('Please ensure your file is a valid image.');
        } else {
            /*
             *Good image upload
             *convert e.dateTransfer.files[0] to a jpg
             */

            this.props.pickImage(e.dataTransfer.files[0]);
            this.setState({ image: e.dataTransfer.files[0] });
        }
        this.setState({ dragging: false });
    }

    handleNonDropFileInput(e) {
        this.props.pickImage(e.target.files[0]);

        this.setState({ image: e.target.files[0] });
    }

    // Changes background color when something is dragged over top
    handleDragIn(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragging: true });
    }

    // Reverts background color when it leaves
    handleDragOut(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragging: false });
    }

    render() {
        if (this.state.dragging) {
            // Need to change the style for the three input type ='file' so that they don't display weird
            return (
                <div className="Dragged-div" ref={this.myRef}>
                    {this.state.image ? null : <h2>Drag Image</h2>}
                    {this.state.image ? (
                        <img
                            src={this.props.imageUrl}
                            alt="error displaying image"
                        ></img>
                    ) : null}
                    <div className="cutoffExtraText">
                        <input
                            type="file"
                            onChange={this.handleNonDropFileInput}
                            accept="image/png, image/jpeg"
                        />
                    </div>
                </div>
            );
        } else if (
            typeof this.state.image !== 'string' &&
            !this.props.imageURL
        ) {
            return (
                <div ref={this.myRef} className="Undragged-div">
                    {this.state.image ? null : <h2>Drag Image</h2>}
                    {this.state.image ? (
                        <img
                            src={URL.createObjectURL(this.state.image)}
                            alt="not the other one"
                        ></img>
                    ) : null}
                    <div className="cutoffExtraText">
                        <input
                            type="file"
                            onChange={this.handleNonDropFileInput}
                            accept="image/png, image/jpeg"
                        />
                    </div>{' '}
                </div>
            );
        } else {
            return (
                <div ref={this.myRef} className="Undragged-div">
                    {this.state.image ? null : <h2>Drag Image</h2>}
                    {this.state.image ? (
                        <img
                            src={
                                typeof this.state.image === 'string'
                                    ? this.state.image
                                    : this.props.imageUrl
                            }
                            alt="not the other one"
                        ></img>
                    ) : null}
                    <div className="cutoffExtraText">
                        <input
                            type="file"
                            onChange={this.handleNonDropFileInput}
                            accept="image/png, image/jpeg"
                        />
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        const listener = this.myRef.current;
        listener.addEventListener('dragenter', this.handleDragIn);
        listener.addEventListener('dragleave', this.handleDragOut);
        listener.addEventListener('drop', this.handleDrop);

        // Prevents opening any uploaded files
        window.addEventListener(
            'dragover',
            function(e) {
                e.preventDefault();
            },
            false
        );
        window.addEventListener(
            'drop',
            function(e) {
                e.preventDefault();
            },
            false
        );
    }

    componentWillUnmount() {
        const listener = this.myRef.current;
        listener.removeEventListener('dragenter', this.handleDragIn);
        listener.removeEventListener('dragleave', this.handleDragOut);
        listener.removeEventListener('drop', this.handleDrop);
    }
}

export default PicturePopup;
