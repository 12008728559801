import React from 'react';
import { APIurl } from '../Router';
import { commonNameAlphabetical, sortBy } from '../CurrentCatalog';
import './MoveEntryPopup.css';
import './Popup.css';
function MySpeciesCard(props) {
    return (
        // For now only
        <div className="popupCatalogCard">
            <p>{props.species.name}</p>
            <p>{props.species.scientificName}</p>
            <button onClick={() => props.markForMovement(props.species)}>
                Move to Current Category
            </button>
        </div>
    );
}

function CardTable(props) {
    const orderedSpecies = sortBy(props.orderFunc, props.speciesList);
    return orderedSpecies.map(species => {
        return (
            <MySpeciesCard
                markForMovement={props.markForMovement}
                species={species}
            />
        );
    });
}
class MoveEntryPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: commonNameAlphabetical,
            speciesList: [],
            proposedMove: { name: '' },
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.removeMemberSpecies = this.removeMemberSpecies.bind(this);
    }

    componentDidMount() {
        const URL = `${APIurl}/species/all`;
        fetch(URL, { method: 'GET' })
            .then(results => {
                return results.json();
            })
            .then(results => {
                this.setState({
                    speciesList: this.removeMemberSpecies(results.species),
                });
            })
            .catch(console.log('There was an error'));
    }

    removeMemberSpecies(allSpecies) {
        const mostSpecies = allSpecies;
        const speciesNames = [];
        for (let j = 0; j < allSpecies.length; j++) {
            speciesNames.push(allSpecies[j].name);
        }
        for (let i = 0; i < mostSpecies.length; i++) {
            if (this.props.containedEntries.includes(speciesNames[i])) {
                /*
                 *Since order of species doesn't matter here, the fastest way to remove a member of an array
                 * is to change that member to equal the last member, and to delete the last member
                 */
                mostSpecies[i] = mostSpecies.pop();
            }
        }
        return mostSpecies;
    }

    render() {
        return (
            <div className="PopupMainBody">
                <div className="PopupTopbar">
                    <button
                        id="entryPopupButton"
                        onClick={() => {
                            this.props.closePopup();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        id="entryPopupButton"
                        onClick={() => {
                            this.props.moveEntry(this.state.proposedMove);
                            this.props.closePopup();
                        }}
                    >
                        Confirm
                    </button>

                    <div className="PopupInfo">
                        Propose to move {this.state.proposedMove.name} into{' '}
                        {this.props.thisCategory}
                    </div>
                </div>
                <div className="popupSpeciesCardHolder">
                    <CardTable
                        speciesList={this.state.speciesList}
                        orderFunc={commonNameAlphabetical}
                        markForMovement={species =>
                            this.setState({ proposedMove: species })
                        }
                    />
                </div>
            </div>
        );
    }
}
export default MoveEntryPopup;
