import React from 'react';
import './Popup.css';
import './../UserAdmin.css';

function ErrorPopup(props) {
    return (
        <div className="Popup">
            <div className="Centered-popup">
                Error: Please enter a valid username and email that don't
                already have an account.
                <button id="userButton" onClick={props.closePopup}>
                    Okay
                </button>
            </div>
        </div>
    );
}

export default ErrorPopup;
