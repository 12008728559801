// Log.js 
// LakeStewards of Maine 2022
// Gets the event log from the API and writes it to the webpage
import React from 'react';
import Popup from './Components/Popup.js';
import SideBar from './Components/SideBar.js';
import TopBar from './Components/TopBar.js';
import { Link } from 'react-router-dom';
import './Log.css';
import './HomePage.css';
import { APIurl } from './Router.js';
import xIcon from './Icons/x_icon_clip_art_9385.jpg';
import pencilIcon from './Icons/download.png';

class Log extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            log: undefined,
        };
    }

    render() {
        return (
            <div style={{ height: '100%' }}>
                {this.state.popupActive ? (
                    this.state.warningPopup ? (
                        <Popup
                            handleCancelClick={this.handleCancelClick}
                            dialogue={"You can't do that you rube"}
                            handleSubmitClick={this.handleCancelClick}
                        />
                    ) : (
                        <Popup
                            handleCancelClick={this.handleCancelClick}
                            handleSubmitClick={this.confirmDeletionProposal}
                            dialogue={`Are you sure you want to delete this species, ${this.state.speciesName}?`}
                            speciesName={this.state.speciesName}
                        />
                    )
                ) : null}
                <TopBar page="Log" />
                <SideBar selected="Download Log" />
                <div id="Body">
                    {/* <CatManSystem/> */}
		<div id="Log-Container">
	 	    <tt className="log">{this.state.log}</tt>
		</div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const URL = `${APIurl}/log`;
        fetch(URL, { method: 'GET', headers:{
		'Content-Type': 'file',
		Authorization: sessionStorage.getItem('token') 
	}}).then(results => {
		const chunks = []
		// the file returned from the API call is in chronologicial
		// but we want to have most recent at the top so we reverse 
		// the order of the string.
		results.blob().then(blob => blob.text().then(text => 
		    this.setState({log: text})));
		results.body.on('data', (chunk) => console.log(chunk));
		
            })
            .catch(e => console.log(`there was an error${e}`));
    }
}

export default Log;
