import React from 'react';
import { Link } from 'react-router-dom';
import SideBar from './Components/SideBar.js';
import TopBar from './Components/TopBar.js';
import { APIurl } from './Router.js';
import './HomePage.css';
import Popup from './Components/Popup.js';

class Foo extends React.Component {
    render() {
        return (
            <div className="Home">
                <TopBar page="Changes" />
                <SideBar selected="Next Release" />
                <Body />
            </div>
        );
    }
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewed: [],
            unreviewed: [],
            categoryChanges: [],
            publicationConfirmationPopup: false,
            publishingPopup: false,
            deleteProposalPopup: undefined,
            categoryChangeToBeCancelled: undefined,
        };
        this.handleUnreview = this.handleUnreview.bind(this);
        this.handleDeleteProposal = this.handleDeleteProposal.bind(this);
        this.loadCatalog = this.loadCatalog.bind(this);
        this.publishChanges = this.publishChanges.bind(this);
        this.handleUndoCategory = this.handleUndoCategory.bind(this);
        this.finalizeProposalDeletion = this.finalizeProposalDeletion.bind(
            this
        );
        this.openCategoryCancelPopup = this.openCategoryCancelPopup.bind(this);
    }

    handleDeleteProposal(name, e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ deleteProposalPopup: name });
    }

    finalizeProposalDeletion(name) {
        const parsedName = name
            .toLowerCase()
            .replace(/ /g, '_')
            .replace(/'/g, '')
            .replace(/-/g, '');
        fetch(`${APIurl}/species/delete_proposal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
                name: name,
                jsonName: `${parsedName}.json`,
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    console.log(`The change failed${response.reason}`);
                } else {
                    this.setState({ deleteProposalPopup: undefined });
                    this.loadCatalog();
                }
            })
            .catch(function () {
                this.setState({ deleteProposalPopup: undefined });
                this.loadCatalog();
            });
    }

    handleUndoCategory(name) {
        fetch(`${APIurl}/tree/revert`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token'),
            },
            body: JSON.stringify({ name: name }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    console.log('Error accessing database');
                }
                this.setState({ categoryChangeToBeCancelled: undefined });
                this.loadCatalog();
            });
    }

    // PROBLEM: doesn't pull from api again and update contents until refresh
    handleUnreview(e, name) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        fetch(`${APIurl}/species/unreview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token'),
            },
            body: JSON.stringify({ name: name }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    console.log(`The change failed${response.message}`);
                } else {
                    this.loadCatalog();
                }
            });
    }

    publishChanges() {
        this.setState({
            publicationConfirmationPopup: false,
            publishingPopup: true,
        });
        fetch(`${APIurl}/catalog/publish`, {
            headers: { Authorization: sessionStorage.getItem('token') },
            method: 'POST',
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    console.log(response);
                    alert('There was an error while publishing');
                    this.setState({ publishingPopup: false });
                    this.loadCatalog();
                } else {
                    this.setState({ publishingPopup: false });
                    this.loadCatalog();
                }
            })
            .catch(err => {
                console.log('The following error occured:');
                console.log(err);
                alert(
                    'There was a non-fatal error while publishing - see console for details'
                );
                this.setState({ publishingPopup: false });
                this.loadCatalog();
            });
    }

    openCategoryCancelPopup(catName) {
        this.setState({ categoryChangeToBeCancelled: catName });
    }

    render() {
        const canPublish =
            this.state.reviewed.length !== 0 ||
            this.state.categoryChanges.length !== 0;
        return (
            <div className="Home-body">
                {this.state.deleteProposalPopup === undefined ? null : (
                    <Popup
                        handleCancelClick={() =>
                            this.setState({ deleteProposalPopup: undefined })
                        }
                        handleSubmitClick={() =>
                            this.finalizeProposalDeletion(
                                this.state.deleteProposalPopup
                            )
                        }
                        dialogue={`Delete the proposal for ${this.state.deleteProposalPopup}?`}
                    />
                )}
                {this.state.categoryChangeToBeCancelled === undefined ? null : (
                    <Popup
                        handleCancelClick={() =>
                            this.setState({
                                categoryChangeToBeCancelled: undefined,
                            })
                        }
                        handleSubmitClick={() =>
                            this.handleUndoCategory(
                                this.state.categoryChangeToBeCancelled
                            )
                        }
                        dialogue={`Are you sure you want to cancel this change? This will delete all unpublished entries contained by ${this.state.categoryChangeToBeCancelled}.`}
                    />
                )}
                {this.state.publicationConfirmationPopup ? (
                    <Popup
                        handleCancelClick={() =>
                            this.setState({
                                publicationConfirmationPopup: false,
                            })
                        }
                        handleSubmitClick={this.publishChanges}
                        dialogue={'Confirm: Publish all reviewed changes'}
                    />
                ) : null}
                {this.state.publishingPopup ? (
                    <Popup
                        isPublishing={true}
                        dialogue={'Publishing all reviewed changes...'}
                    />
                ) : null}
                {/* <CatManSystem/> */}
                <div className="categoryChangesHolder">
                    <table className="categoryChangeTable">
                        <tbody>
                            <tr>
                                <th className="Change-table-header">
                                    {' '}
                                    Category Changes
                                </th>
                            </tr>
                            <tr>
                                <td className="Change-table-cell">
                                    <div id="Card-holder">
                                        {fillCategoryCards(
                                            this.state.categoryChanges,
                                            this.openCategoryCancelPopup
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="Change-section">
                    {/* <Link to="/new_entry">
                        <button>Propose New Entry</button>
                    </Link>
                    <Link to="/catalog">
                        <button>Propose Changes</button>
                                    </Link> */}
                    {sessionStorage.getItem('permission') >= 2 && canPublish ? (
                        <button
                            className="Publish-button"
                            onClick={() => {
                                this.setState({
                                    publicationConfirmationPopup: true,
                                });
                            }}
                        >
                            Publish Changes
                        </button>
                    ) : null}

                    <table className="Change-table">
                        <tbody>
                            <tr>
                                <th className="Change-table-header">
                                    Unreviewed
                                </th>
                                <th className="Change-table-header">
                                    Reviewed
                                </th>
                            </tr>
                            <tr>
                                <td className="Change-table-cell">
                                    <UnreviewedChanges
                                        contents={this.state.unreviewed}
                                        handleDeleteProposal={
                                            this.handleDeleteProposal
                                        }
                                    />
                                </td>
                                <td className="Change-table-cell">
                                    <ReviewedChanges
                                        contents={{
                                            reviewed: this.state.reviewed,
                                            categories: this.state
                                                .categoryChanges,
                                        }}
                                        handleUnreview={this.handleUnreview}
                                        handleUndoCategory={
                                            this.handleUndoCategory
                                        }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    loadCatalog() {
        const URL = `${APIurl}/catalog/unpublished`;

        const categoryURL = `${APIurl}/tree/changes`;

        fetch(categoryURL, { method: 'GET' })
            .then(results => {
                return results.json();
            })
            .then(catData => {
                const cats = catData.categories.map(category => {
                    let changeType;
                    if (category.modified) {
                        changeType = 'Update';
                    } else if (category.added) {
                        changeType = 'Addition';
                    } else {
                        changeType = 'Removal';
                    }
                    return {
                        name: category.name,
                        change: changeType,
                        movedSpecies: category.movedSpecies,
                    };
                });

                this.setState({ categoryChanges: cats });

                fetch(URL, { method: 'GET' })
                    .then(results => {
                        return results.json();
                    })
                    .then(data => {
                        const reviewed = [],
                            unreviewed = [];

                        data.species.map(species => {
                            let changed;
                            if (species.added) {
                                changed = 'Addition';
                            } else if (species.modified) {
                                changed = 'Update';
                            } else {
                                changed = 'Removal';
                            }

                            const foo = {
                                name: species.name,
                                scientificName: species['scientificName'],
                                reviewed: species.reviewed,
                                changeType: changed,
                                changedBy: species.changedBy,
                                changedOn: species.changedOn,
                            };

                            if (species.reviewed) {
                                reviewed.push(foo);
                            } else {
                                unreviewed.push(foo);
                            }
                        });

                        this.setState({
                            reviewed: reviewed,
                            unreviewed: unreviewed,
                        });
                    })
                    .catch(e => console.log(`there was an error${e}`));
            });
    }

    componentDidMount() {
        this.loadCatalog();
        setTimeout(
            function () {
                // Start the timer
                this.loadCatalog(); // After 1 second, set render to true
            }.bind(this),
            500
        );
        setTimeout(
            function () {
                // Start the timer
                this.loadCatalog(); // After 1 second, set render to true
            }.bind(this),
            2500
        );
    }
}

function SearchBar(props) {
    return <input type="text" id="Search-bar" />;
}

function UnreviewedChanges(props) {
    return (
        <div id="Card-holder">
            {fillUnreviewedCards(props.contents, props.handleDeleteProposal)}
        </div>
    );
}

// Helper function to fill the cards
function fillUnreviewedCards(contents, deleteProposal) {
    const cards = contents.map(function (ele, j) {
        return (
            <Card
                key={j}
                contents={ele}
                reviewed={false}
                handleDeleteProposal={deleteProposal}
            />
        );
    });
    return cards;
}

function ReviewedChanges(props) {
    return (
        <div id="Card-holder">
            {fillReviewedCards(props.contents.reviewed, props.handleUnreview)}
        </div>
    ); // Move fillCategoryCards to where the new section for looking at changes to categories is.
}

function fillCategoryCards(contents, undoPopup) {
    const cards = contents.map(function (ele, i) {
        return (
            <CategoryCard
                key={i}
                contents={ele}
                openCategoryCancelPopup={undoPopup}
            />
        );
    });
    return cards;
}

function fillReviewedCards(contents, unreview) {
    const cards = contents.map(function (ele, j) {
        return (
            <Card
                key={j}
                contents={ele}
                reviewed={true}
                handleUnreview={unreview}
            />
        );
    });
    return cards;
}
/*async function getTree() {
    let toReturn;
    await fetch(`${APIurl}/tree/all`, {
        method: 'GET',
        headers: { Authorization: sessionStorage.getItem('token') },
    })
        .then(response => response.json())
        .then(response => {
            toReturn = response.tree;
            return response.tree;
        });
    return toReturn;
}*/
class CategoryCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parents: [],
        };
    }

    componentDidMount() {
        // Var tree = getTree()

        let answer;
        function isBottomCategory(subTree) {
            // If the only things a category has in it are its invasive and uninvasive images and species (which aren't noted in this tree), then it is a bottom level category
            return Object.keys(subTree).length <= 2;
        }
        function helper(subTree, sofarList, name) {
            for (let i = 0; i < Object.keys(subTree).length; i++) {
                const ithKey = Object.keys(subTree)[i];
                if (ithKey !== 'image' && ithKey !== 'invasiveImage') {
                    if (ithKey === name) {
                        answer = sofarList;
                    } else if (!isBottomCategory(subTree)) {
                        const clone = sofarList.slice(0);
                        clone.push(ithKey);
                        helper(subTree[ithKey], clone, name);
                    }
                }
            }
            return answer;
        }
        fetch(`${APIurl}/tree/all`, {
            method: 'GET',
            headers: { Authorization: sessionStorage.getItem('token') },
        })
            .then(response => response.json())
            .then(response => {
                const x = helper(response.tree, [], this.props.contents.name);
                this.setState({ parents: x });
            });

        // Could be right. Hard to tell cuz there's other stuff which doesn't work which would probably cause the same problems whether or not this worked.
    }

    render() {
        let topButton;
        if (sessionStorage.getItem('permission') >= 2) {
            topButton = (
                <button
                    id="Delete-proposal-button"
                    onClick={() =>
                        this.props.openCategoryCancelPopup(
                            this.props.contents.name
                        )
                    }
                >
                    Cancel Change
                </button>
            );
        } else {
            topButton = null;
        }

        const style = this.props.contents.change;
        const changeText = this.props.contents.change;
        return (
            <div className={'categoryChangeCard'}>
                {topButton}
                <Link
                    to={{
                        pathname: '/lsadmin/edit_category',
                        state: {
                            categoryName: this.props.contents.name,
                            categoryParents: this.state.parents,
                        },
                    }}
                >
                    <button>Check Change</button>
                </Link>
                <p className="Secondary-text">{this.props.contents.name}</p>
                <p className={`Secondary-text ${style}`}>{changeText}</p>
                {this.props.contents.movedSpecies.map(species => {
                    return (
                        <p className="Moved-species">
                            {`Moved ${species.name} from ${species.oldCategory}`}
                        </p>
                    );
                })}
            </div>
        );
    }
}

function removeTimeStamp(date) {
    if (date === null) {
        return null;
    } else {
        const stringDate = date.toString();
        return stringDate.slice(0, 10);
    }
}

function Card(props) {
    const rightStyle = props.contents.changeType;

    let topButton;
    if (sessionStorage.getItem('permission') >= 2) {
        if (props.reviewed) {
            topButton = (
                <button
                    id="Delete-proposal-button"
                    onClick={e => props.handleUnreview(e, props.contents.name)}
                >
                    Mark As Unreviewed
                </button>
            );
        } else {
            topButton = (
                <button
                    id="Delete-proposal-button"
                    onClick={e =>
                        props.handleDeleteProposal(props.contents.name, e)
                    }
                >
                    Delete Proposal
                </button>
            );
        }
    } else {
        topButton = null;
    }
    return (
        <div className="Unreviewed-card">
            {sessionStorage.getItem('permission') >= 2 ? (
                <Link
                    to={{
                        pathname: '/lsadmin/review_entry',
                        state: {
                            name: props.contents.name,
                            changeType: props.contents.changeType,
                        },
                    }}
                >
                    <div className="individualCardHolder">
                        <div className="individualLeftSection">
                            {topButton}

                            <p className="Primary-text">
                                {props.contents.name}
                            </p>
                            <p className="Secondary-text">
                                {props.contents.scientificName}
                            </p>
                            <p>{props.contents.category}</p>
                        </div>
                        <div className={`individualRightSection ${rightStyle}`}>
                            <p>{props.contents.changeType}</p>
                            <p>By: {props.contents.changedBy}</p>
                            <p>
                                On: {removeTimeStamp(props.contents.changedOn)}
                            </p>
                        </div>
                    </div>
                </Link>
            ) : (
                <div className="individualCardHolder">
                    <div className="individualLeftSection">
                        <p className="Secondary-text">{props.contents.name}</p>
                        <p className="Secondary-text">
                            {props.contents.scientificName}
                        </p>
                        <p>{props.contents.category}</p>
                    </div>
                    <div className={`individualRightSection ${rightStyle}`}>
                        <p>{props.contents.changeType}</p>
                        <p>By: {props.contents.changedBy}</p>
                        <p>On: {removeTimeStamp(props.contents.changedOn)}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export { TopBar, SideBar, SearchBar, removeTimeStamp };
export default Foo;
