import React from 'react';
import { imageurl } from '../../Router.js';
import './CategoryCard.css';

// The cards that will display simplified species information (name, image)

class CategoryCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryName: this.props.name,
            image: this.props.image,
            invasiveImage: this.props.invasiveImage,
        };
    }

    render() {
        let className = '';
        let backName = 'back';
        if (this.props.name.length < 10) {
            className = 'category-text';
        } else if (this.props.name === 'other aquatic fauna') {
            className = 'other-aquatic';
        } else if (this.props.name.length < 26) {
            className = 'long-cat-text one-line';
        } else {
            backName = 'long-back';
            className = 'long-cat-text multi-line';
        }
        return (
            <div
                className={
                    this.props.type === 'Back'
                        ? 'back-card'
                        : !this.props.firstLayer
                            ? 'category-card'
                            : 'first-category-card category-card'
                }
                onClick={() => this.props.navigate(this.props.name)}
            >
                {this.props.picture ? (
                    <div className="cat-image-holder">
                        <img
                            className={
                                !this.props.firstLayer
                                    ? 'category-image'
                                    : 'first-cat-image'
                            }
                            src={`${imageurl}${this.props.picture}`}
                            alt={this.props.picture}
                        />
                    </div>
                ) : null}
                <p
                    className={
                        this.props.type === 'Back' ? backName : className
                    }
                >
                    {this.props.name
                        .toLowerCase()
                        .split(' ')
                        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' ')}
                </p>
            </div>
        );
    }
}

export default CategoryCard;
