import React from 'react';
import SideBar from './Components/SideBar.js';
import TopBar from './Components/TopBar.js';
import './UserAdmin.css';
import './NewUser.css';
import ErrorPopup from './Components/ErrorPopup.js';
import ConfirmationPopup from './Components/ConfirmationPopup.js';
import { APIurl } from './Router.js';

class NewUser extends React.Component {
    render() {
        return (
            <div>
                <TopBar page="Create New User" />
                <SideBar />
                <Contents />
            </div>
        );
    }
}

class Contents extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangePermission = this.handleChangePermission.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.checkCompletion = this.checkCompletion.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.makeAccount = this.makeAccount.bind(this);
        this.state = {
            username: '',
            email: '',
            permissionLevel: 'User',
            password: '',
            showErrorPopup: false,
            showConfirmationPopup: false,
        };
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value,
        });
    }

    handleChangeUsername(event) {
        this.setState({
            username: event.target.value,
        });
    }

    handleChangeEmail(event) {
        this.setState({
            email: event.target.value,
        });
    }

    handleChangePermission(event) {
        this.setState({
            permissionLevel: event.target.value,
        });
    }

    checkCompletion() {
        return (
            this.state.email !== '' && this.state.username !== '' && true
            // Should check database to prevent repeat accounts
        );
    }

    closePopup() {
        this.setState({
            showErrorPopup: false,
            showConfirmationPopup: false,
        });
    }

    // Temporarily creates entire account with password
    makeAccount() {
        const URL = `${APIurl}/user/add`;
        let role;
        switch (this.state.permissionLevel) {
        case 'Administrator':
            role = 2;
            break;
        case 'Editor':
            role = 1;
            break;
        default:
            role = 0;
            break;
        }
        const data = JSON.stringify({
            name: this.state.username,
            password: this.state.password,
            email: this.state.email,
            role: role,
        });

        fetch(URL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token'),
            },
            body: data,
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    console.log(`The creation failed${response.message}`);
                }
            })
            .catch(err => console.log(err));

        this.setState({ showConfirmationPopup: false });
        /*
         *Create a new account, without a password yet, that can later have its password set by email
         *send an email to the given email, asking for a password to be made;
         *also give a popup saying message sent
         */
    }

    handleSubmit() {
        if (this.checkCompletion()) {
            this.setState({ showConfirmationPopup: true });
        } else {
            this.setState({ showErrorPopup: true });
            // Give an error popup
        }
    }

    render() {
        return (
            <div>
                <label> username: </label>
                <input
                    className="basicField"
                    onChange={this.handleChangeUsername}
                />
                <label> email: </label>
                <input
                    className="basicField"
                    onChange={this.handleChangeEmail}
                />
                <label> password: </label>
                <input
                    className="basicField"
                    onChange={this.handlePasswordChange}
                />
                <label> permission level:</label>
                <select
                    value={this.state.permissionLevel}
                    onChange={this.handleChangePermission}
                >
                    <option value="User"> User </option>
                    <option value="Editor"> Editor</option>
                    <option value="Administrator"> Administrator</option>
                </select>
                <button id="submitButton" onClick={this.handleSubmit}>
                    Submit
                </button>
                {this.state.showErrorPopup ? (
                    <ErrorPopup closePopup={this.closePopup} />
                ) : null}

                {this.state.showConfirmationPopup ? (
                    <ConfirmationPopup
                        closePopup={this.closePopup}
                        confirm={this.makeAccount}
                        email={this.state.email}
                    />
                ) : null}
            </div>
        );
    }
}
export default NewUser;
