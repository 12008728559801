import React from 'react';
import './Login.css';
import { Redirect } from 'react-router-dom';
import { APIurl } from './Router.js';
import './Components/Popup.css';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            password: '',
            loggedIn: false,
            gotoRegistration: false,
            incorrectCredentials: false,
        };
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    handleNameChange(e) {
        this.setState({ name: e.target.value });
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    handleLoginClick() {
        this.setState({ incorrectCredentials: false, usersCantLogin: false });
        if (this.state.name !== '' && this.state.password !== '') {
            const URL = `${APIurl}/user/signin`;
            const data = JSON.stringify({
                name: this.state.name,
                password: this.state.password,
            });
            fetch(URL, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: data,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.id_token !== undefined) {
                        sessionStorage.setItem('token', response.id_token);
                        sessionStorage.setItem(
                            'permission',
                            response.permission
                        );
                        if (response.permission === 0) {
                            this.setState({ usersCantLogin: true });
                        } else if (response.permission > 0) {
                            this.setState({ loggedIn: true });
                        }
                    } else {
                        this.setState({ incorrectCredentials: true });
                    }
                })
                .catch(err => console.log(err));
        }
    }

    render() {
        return (
            <div id="Login">
                {this.state.loggedIn ? <Redirect to="/lsadmin/changes" /> : null}
                {this.state.gotoRegistration ? (
                    <Redirect to="/lsadmin/new_user" />
                ) : null}
                <h1>Lake Stewards of Maine</h1>
                <h2>Login</h2>
                {this.state.incorrectCredentials ? (
                    <h3>Invalid username or password</h3>
                ) : null}
                {this.state.usersCantLogin ? (
                    <h3>
                        A new user must have their account's permission changed
                        before they can log in.
                    </h3>
                ) : null}
                <label>Username: </label>
                <input type="text" onChange={this.handleNameChange} />
                <br />
                <label>Password: </label>
                <input type="password" onChange={this.handlePasswordChange} />
                <br />
                <button id="Login-button" onClick={this.handleLoginClick}>
                    Login
                </button>
                <button
                    id="Login-button"
                    onClick={() => {
                        this.setState({ gotoRegistration: true });
                    }}
                >
                    {' '}
                    Register{' '}
                </button>
            </div>
        );
    }
}

export default Login;
