import React from "react";
import "./TopBar.css";
import Logo from "./TopBarIcon.js";

const TopBar = props => {
  return (
    <div className="TopBar">
      <Logo className="BarImage" />
    </div>
  );
};

export default TopBar;
